import withNavigateHook from "../../components/WithNavigation";
import { connect } from "react-redux";
import { clearStoreItem } from "../../store/actions/actions";
import { useEffect } from "react";
import "./success.scss";
import { useLocation } from "react-router-dom";

const SuccessPage = (props: any) => {



    useEffect(() => {
        setTimeout(() => {
            if (props?.auth3dthird) {
                if (props?.auth3dthird?.type === 'apple') {
                    if (props.user?.email && props.user?.phone)
                        props.navigate("/Authentification/personal-info")
                }
                else {
                    if (props.user?.email && props.user?.phone && props.user.firstName && props.user.lastName)
                        props.navigate("/Authentification/personal-info")
                }
            }else {
                if (props.user?.email && props.user?.phone && props.userMode == 2) {
                    props.navigate("/Authentification/personal-info")
                }

              else if (props.user?.email && props.user?.phone && props.userMode == 2 && props.user.firstName && props.user.lastName) {
                  props.navigate("/Authentification")
              }

              else if (props.user?.email && props.user?.phone && props.userMode == 1) {
                  props.navigate("/Authentification")
              }

              else {
                  props.user.type == "email" ? props.navigate("/Authentification", { state: {forUser: "phone"}, replace: true }) : props.navigate("/Authentification", { state: {forUser: "email"}, replace: true })
              }
          }
      }, 100); // externalize config config.success_timout_animation
  }, [])

  return (
    <div className="successPage">
      <div className="page-content">
        <div className="main-container">
          <div className="check-container">
            <div className="check-background">
              <svg
                viewBox="0 0 65 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 25L27.3077 44L58.5 7"
                  stroke="white"
                  stroke-width="13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="check-shadow"></div>
          </div>
              {/* <h1>Success</h1> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    authRetries: state.auth.authRetries,
    userMode: state.auth.userMode,
    auth3dthird: state.auth.auth3dthird,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(SuccessPage));
