import React from "react";
import restuarntNameExample from "../../../images/restuarntNameExample.png";
import QRcodeTest from "../../../images/QRcodeTest.svg";
import shoppingCart from "../../../images/shopping-cart.svg";
import CloseIcon from "../../../images/CloseIcon_btn.svg";
import WishListIcon from "../../../images/WishListIcon.svg";
import { Link } from "react-router-dom";
import MenuSection from "./MenuElements/MenuSection";
import moment from "moment";
import QRCode from "react-qr-code";

export default function InvoiceCommand({
  command,
  qrcode,
  clearItemStore,
}: {
  command?: serviceDetailsOrderModels.detailsOrder;
  qrcode: string;
  clearItemStore: () => void;
}) {
  const convertHexToByteArray = (hexString: string) => {
    
    const byteCharacters = atob(hexString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  };

 

  let byteArray = convertHexToByteArray(qrcode);
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  const imageUrl = URL.createObjectURL(blob);
  return (
    <div className="InvoiceCommand">
      <div className="InvoiceContainer">
        <Link
          className="btn-cmd"
          to="/mes-commandes"
          onClick={() => clearItemStore()}
        >
          <img src={CloseIcon} alt="" />
        </Link>
        <div className="invoice-header">
          <img className="restaurant-img" src={restuarntNameExample} alt="" />
          <p className="restaurant-name">{command?.nomRestaurant}</p>
          <img src={WishListIcon} alt="" className="wishList" />
        </div>
        <div className="invoice-body">
          <div className="QrCode">
            <p>Votre ticket</p>
            {command?.status === "cashPayment" ? ( <img className="qrCodeImg" src={imageUrl} alt="" />): null}
           

            {command && (
              <div className="Commandinfo">
                <p className="command-number">
                  Commande N° {command?.referenceCommande}
                </p>
                {/* <p className="command-time">A emporter : entre 19:30 et 20:00 </p> */}
              </div>
            )}
          </div>
          {command && (
            <div className="Ticket-details">
              <div className="ticket-header">
                {/* <p className="title-ticket">Votre ticket</p> */}
                <div>
                  <p className="time-ticket">
                    Aujourd’hui à {moment().format("HH:mm")}
                  </p>
                  <p className="delivery-ticket">{command.diningMode==0 ? "A emporter" : "Sur place"}</p>
                </div>
              </div>

              <div className="ticket-menu-pricing">
                {command.detailCommande?.map((detail) => (
                  <MenuSection detail={detail} />
                ))}
              </div>
            </div>
          )}
        </div>
        {command && (
          <div className="invoice-footer">
            <div className="subtotal">
              <p>Sous-total</p>
              <p>{command?.totalPrix.toFixed(2)}€</p>
            </div>
            <div className="expenses">
              <p>Frais</p>
              <p>0,00€</p>
            </div>
            <div className="total">
              <p>Total</p>
              <p>{command?.totalPrix?.toFixed(2)}€</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
