import React, { useEffect, useState } from "react";
import NavBar from "../../components/Nbar/Nav-bar";
import Footer from "../../components/AppLayout/footer/Footer";
import "./paiement.scss";
import LeftArrow from "../../images/arrow-left_grey.svg";
import { Link, useNavigate } from "react-router-dom";
import DistanceIcon from "../../images/distanceIcon.svg";
import clockIcon from "../../images/time-outline.svg";
import { GoogleMap, Marker } from "@react-google-maps/api";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Navigate } from "react-router-dom";
import { StripeCardElement, loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { config } from "../../config";
import ProcessingCommand from "./ProcessingCommand/ProcessingCommand";
import InvoiceCommand from "./ProcessingCommand/InvoiceCommand";
import { languageData } from "../../i18n";
import CheckoutForm from "./CheckoutForm";
import { connect } from "react-redux";
import {
  clearStoreItem,
  getmyprofile,
  pushCategory,
} from "../../store/actions/actions";
import Planification from "./ProcessingCommand/Planification";

const Paiement = (props: any) => {
  const [showMarker, setshowMarker] = useState(false);
  const [isOnlinePaymentChecked, setOnlinePaymentChecked] = useState(false);
  const [isMaintenantChecked, setIsMaintenantChecked] = useState(false);
  const [showProcessingCommand, setShowProcessingCommand] = useState(false);
  const [objectcategory, setObjectCategory] = useState(
    localStorage.getItem("resturantId")
  );

  console.log("isMaintenantChecked", isMaintenantChecked);

  const [isValidTime, setIsValidTime] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>(null); // Assuming you want to store restaurant data
  const stripePromise = loadStripe(config.stripe_public_key);
  const [promptCommand, setpromptCommand] = useState(false);
  const [command, setcommand] = useState();
  const [qrcode, setqrcode] = useState("");

  const navigate = useNavigate();

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOnlinePaymentChecked(event.target.value === "online");
  };

  const handlePlanificationMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsMaintenantChecked(event.target.value === "planifier");
  };

  useEffect(() => {
    setShowProcessingCommand(false);
  }, []);

  useEffect(() => {
    if (
      restaurantData === undefined ||
      restaurantData?.length === 0 ||
      objectcategory === null
    ) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (objectcategory !== null && props.savedCategories) {
      const data = props.savedCategories[objectcategory];
      setRestaurantData(data);
      if (!data?.restaurant?.isOnSpotPaymentActive) {
        setOnlinePaymentChecked(true);
      }
    }
  }, [objectcategory, props.savedCategories, rerender]);

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [objectcategory]);

  const submitCommand = () => {
    window.scrollTo(0, 0);
    document.querySelector(".paimentSection")?.classList.add("slideUpHide");
    document
      .querySelector(".ProcessingCommandComponent")
      ?.classList.add("slideUpShow");
    setShowProcessingCommand(true);
    setTimeout(() => {
      ShowInvoice();
    }, 4500);
  };

  const ShowInvoice = () => {
    document
      .querySelector(".InvoiceCommandComponent")
      ?.classList.add("DisplayInvoice");
  };

  useEffect(() => {
    const data = props.getmyprofile();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("redirectedToAuth")) {
      localStorage.setItem("redirectedToAuth", "false");
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setshowMarker(true);
    }, 2000);
  }, []);
  const onLoad = (marker: any) => {};
  const center = {
    lat: props.location?.lat,
    lng: props.location?.lng,
  };

  const style = {
    height: "10vh",
    width: "100%",
  };
  const clearRestauId = () => {
    let obj = props.savedCategories;
    delete obj[objectcategory as string];

    props.pushCategory(obj);
  };

  let min = restaurantData?.restaurant?.shifts[0]?.open;
  let max = restaurantData?.restaurant?.shifts[0]?.close;
  let prepdate = restaurantData?.restaurant.tempsPrep;

  const todayDate = dayjs().format("YYYY-MM-DD");
  const OpenTime = dayjs(`${todayDate}T${min}`);
  const ClosedTime = dayjs(`${todayDate}T${max}`);

  const combinedDateTime = dayjs(`${todayDate}T${min}`).add(prepdate, "minute");
  const combinedDateTime2 = dayjs(`${todayDate}T${max}`).subtract(
    prepdate,
    "minute"
  );

  const [selectedTimee, setSelectedTimee] = useState(combinedDateTime);
  const [selectedTimeRight, setSelectedTimeRight] = useState("");

  useEffect(() => {}, [selectedTimeRight]);

  const handleTimeChange = (newValue: any) => {
    const selectedHours = newValue.hour();
    const selectedMinutes = newValue.minute();
    const selectedTotalMinutes = selectedHours * 60 + selectedMinutes;

    const minHours = parseInt(min.split(":")[0]);
    const minMinutes = parseInt(min.split(":")[1]);
    const minTotalMinutes = minHours * 60 + minMinutes;

    const maxHours = parseInt(max.split(":")[0]);
    const maxMinutes = parseInt(max.split(":")[1]);
    const maxTotalMinutes = maxHours * 60 + maxMinutes;

    if (
      selectedTotalMinutes < minTotalMinutes ||
      selectedTotalMinutes > maxTotalMinutes
    ) {
      setIsValidTime(false);
      setSelectedTimee(combinedDateTime2);
      setSelectedTimeRight(combinedDateTime.format("HH:mm"));
    } else {
      setSelectedTimee(newValue);
      setIsValidTime(true);
      setSelectedTimeRight(newValue.format("HH:mm"));
    }
  };
  const currentDateTime = dayjs();
  const selectedDateTime = dayjs(`${todayDate}T${selectedTimeRight}`);

  useEffect(() => {
    if (!isValidTime) {
      setIsValidTime(true);
    }

    const currentDateTime = dayjs();
    const selectedDateTime = dayjs(`${todayDate}T${selectedTimeRight}`);

    if (currentDateTime.isAfter(selectedDateTime)) {
      setSelectedTimeRight(currentDateTime.format("HH:mm"));
      // console.log(
      //   "Current time is befor the selected time.",
      //   currentDateTime.format("HH:mm")
      // );
    } else {
      console.log("Current time is after or equal to the selected time.");
    }
  }, [selectedTimeRight, isValidTime, todayDate]);

  let value;

  if (isValidTime) {
    value = combinedDateTime;
  } else {
    value = combinedDateTime2;
  }

  if (
    currentDateTime.isAfter(selectedDateTime) ||
    selectedDateTime.format("HH:mm") <
      currentDateTime.add(prepdate, "minute").format("HH:mm")
  ) {
    value = currentDateTime.add(prepdate, "minute");
  } else {
    value = selectedTimee;
    if (selectedTimee.format("HH:mm") > combinedDateTime2.format("HH:mm")) {
      console.log("true is biger ");
      value = currentDateTime.add(prepdate, "minute");
    } else {
      console.log("false is less ");
      value = selectedTimee;
    }
  }

  return (
    <div className="PaiementPage">
      <section>
        <NavBar />
        <div className="body-wrapper">
          <div className="TopPageHeader">
            <div onClick={() => navigate(-1)}>
              <div>
                <img src={LeftArrow} alt="" />
                <p>Paiement</p>
              </div>
            </div>
          </div>
          <main className="paimentSection">
            <div className="body-paiment">
              <div className="body-wrapper">
                <div className="map-location">
                  <div className="restaurant-name">
                    <p>
                      {objectcategory !== null
                        ? restaurantData?.restaurant?.nom
                        : ""}
                    </p>
                  </div>
                  <div className="location-adresse">
                    <p>
                      {objectcategory !== null
                        ? restaurantData?.restaurant?.address
                        : ""}
                    </p>
                  </div>
                  <div className="map-convas">
                    <div className="map">
                      <GoogleMap
                        options={{
                          disableDefaultUI: true, // Disable default UI controls
                          draggable: false, // Disable dragging
                          clickableIcons: false, // Disable clickable icons
                        }}
                        mapContainerStyle={style}
                        zoom={15}
                        center={center}
                      >
                        {showMarker && (
                          <Marker opacity={1} options={{}} position={center} />
                        )}
                      </GoogleMap>
                    </div>
                    <div className="location-details">
                      <div className="distance">
                        <img src={DistanceIcon} alt="" />
                        <div>
                          <p>Distance</p>
                          <p>0,2 Km</p>
                        </div>
                      </div>
                      <div className="delivery-type">
                        <p className="label">Maintenant</p>
                        <p>
                          <b>
                            {objectcategory !== null
                              ? restaurantData?.mode == 0
                                ? "À Emporter"
                                : "Sur place"
                              : null}
                            , prête dans :{" "}
                            {objectcategory !== null
                              ? restaurantData?.restaurant?.tempsPrep
                              : ""}{" "}
                            min
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`payment-methode-container  show-card `}>
                  <p className="block-title">Méthode de Planification</p>
                  <div
                    className="second-Input"
                    style={{
                      gap: "15px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="onsite-payment  payment-methode-field ">
                      <input
                        type="radio"
                        name="payment-planification"
                        id="maintenant"
                        value="maintenant"
                        checked={!isMaintenantChecked}
                        onChange={handlePlanificationMethodChange}
                      />
                      <label htmlFor="onsite">Maintenant</label>
                      {!isMaintenantChecked && (
                        <div style={{ paddingLeft: "30px" }}>
                          <p>Prête dans : {prepdate} min</p>
                        </div>
                      )}
                    </div>
                    <div className="onsite-payment payment-methode-field">
                      <input
                        type="radio"
                        name="payment-planification"
                        id="planifier"
                        value="planifier"
                        checked={isMaintenantChecked}
                        onChange={handlePlanificationMethodChange}
                      />
                      <label htmlFor="onsite">Planifier</label>
                      {isMaintenantChecked && (
                      <div className="chechkingg">
                        <p>
                          Horaire : {OpenTime.format("HH:mm")} a{" "}
                          {ClosedTime.format("HH:mm")}
                        </p>
                        <div className="field-time">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["MobileTimePicker"]}>
                            <DemoItem>
                              <MobileTimePicker
                                value={value}
                                onChange={handleTimeChange}
                                ampm={false}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <img src={clockIcon} alt="" />
                        </div>
                      </div>
                    )}
                    </div>
                    
                  </div>
                </div>
                <div className={`payment-methode  show-card `}>
                  <p className="block-title">Méthode de paiement</p>
                  <div className="payments-wrapper">
                    {restaurantData?.restaurant?.isOnSpotPaymentActive && (
                      <div className="onsite-payment">
                        <input
                          type="radio"
                          id="onsite"
                          name="payment-methode"
                          value="onsite"
                          checked={!isOnlinePaymentChecked}
                          onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="onsite">Sur place</label>
                      </div>
                    )}
                    {restaurantData?.restaurant?.isStripeActive && (
                      <div className="online-payment">
                        <input
                          type="radio"
                          id="online"
                          name="payment-methode"
                          value="online"
                          checked={isOnlinePaymentChecked}
                          onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="online">En ligne</label>
                      </div>
                    )}
                  </div>
                  <div className="payment-info">
                    <div className="sous-total">
                      <p className="label">Sous-total</p>
                      <p className="value">
                        {objectcategory !== null &&
                        restaurantData?.categories?.length > 0
                          ? restaurantData.categories
                              .reduce((total: number, category: any) => {
                                return (
                                  total + category.amount * category.quantity
                                );
                              }, 0)
                              .toFixed(2)
                          : "15"}
                        €
                      </p>
                    </div>
                    <div className="frais">
                      <p className="label">Frais</p>
                      <p className="value">0,00€</p>
                    </div>
                    <div className="total">
                      <p className="total-label">Total</p>
                      <p className="total-value">
                        {" "}
                        {objectcategory !== null &&
                        restaurantData?.categories?.length > 0
                          ? restaurantData.categories
                              .reduce((total: number, category: any) => {
                                return (
                                  total + category.amount * category.quantity
                                );
                              }, 0)
                              .toFixed(2)
                          : "15"}
                        €
                      </p>
                    </div>
                  </div>
                  <div className="card-section">
                    <Elements
                      stripe={stripePromise}
                      options={{
                        mode: "payment",
                        amount: 350,
                        currency: "eur",
                      }}
                    >
                      <CheckoutForm
                        setqrcode={(str: string) => setqrcode(str)}
                        setcommand={(command: any) => setcommand(command)}
                        setpromptCommand={(value: boolean) =>
                          setpromptCommand(value)
                        }
                        isOnlinePaymentChecked={isOnlinePaymentChecked}
                        setShowProcessingCommand={() =>
                          setShowProcessingCommand(true)
                        }
                        isValidTime={isValidTime}
                        isMaintenantChecked={isMaintenantChecked}
                        selectedTimeRight={selectedTimeRight}
                      ></CheckoutForm>
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {showProcessingCommand && (
            <div className="ProcessingCommandComponent">
              <ProcessingCommand
                resturant={restaurantData?.restaurant?.nom}
                distance={restaurantData?.restaurant?.address}
                tempsPrep={restaurantData?.restaurant?.tempsPrep}
                placement={
                  restaurantData?.mode == 0 ? "À Emporter" : "Sur place"
                }
              />
            </div>
          )}
          {promptCommand && (
            <div className="InvoiceCommandComponent ">
              <InvoiceCommand
                clearItemStore={clearRestauId}
                command={command}
                qrcode={qrcode}
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    location: state.auth.location,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getmyprofile: () => dispatch(getmyprofile()),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Paiement);
