import React from "react";
import { Commande } from "../../../models/services/module.order";

export default function CartHeaderDetails({ order }: { order?: Commande }) {
  return (
    <div className="CartHeader">
      <div className="wrapper">
        <p className="restaurantName">{order?.nomRestaurant}</p>
        <div className="col-wrapper">
          <p className="restaurantAdresse">
            {order?.diningMode == 0 ? "A Emporter" : "Sur place"}
          </p>
          <p className="articlesSlot">{order?.nbArticles} article(s)</p>
        </div>
      </div>
      <div className="seperator-cart"></div>
    </div>
  );
}
