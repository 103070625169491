import React from "react";
import "./skeleton.css";

interface SkeletonElementProps {
  type: string;
}

const SkeletonElement: React.FC<SkeletonElementProps> = ({ type }) => {
  const className = `skeleton ${type}`;

  if (type === "img-block") {
    return <div className={className}></div>;
  }

  return <div className={className}></div>;
};

export default SkeletonElement;
