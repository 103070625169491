import React, { useEffect, useState } from "react";

// import TrashOrange from "../../../images/trashOrange.svg";
// import PlusboxOrange from "../../../images/plusboxOrange.svg";
// import MinusboxOrange from "../../../images/minusboxOrange.svg";
// import foodImg from "../../../images/food.png";
import CartSubElements from "./CartSubElements";
import Bin from "../../../images/red_bin.svg";
import { useLocation } from "react-router-dom";
import "./cart.scss";
import { pushCategory, selectCategory } from "../../../store/actions/actions";
import { connect } from "react-redux";

const CartElements = (props: any) => {
  const [quantity, setQuantity] = useState(1);
console.log("objectpropssssss",props)
  const location = useLocation();
  const [savedCat, setSavedCat] = useState<any>(props.savedCategories);


  useEffect(() => {
    setSavedCat(props.savedCategories);
  }, [savedCat]);
  const resturantId = localStorage.getItem("resturantId");
  const targetResturantId = resturantId !== null ? resturantId : location?.pathname?.split("/")[2];
  console.log("targetResturantId",props.savedCategories)
  const handleDeleteCommand = () => {
    console.log("targetResturantId",targetResturantId)

    let obj = props.savedCategories;

    delete obj[targetResturantId];
    props.pushCategory(obj);
    props.setShowCommandDetails()
  };

  return (
    <div className="CartElements">
      <div className="CartsElements-listing">
        {props.category?.categories?.map((item: any, index: any) => (
          <CartSubElements
            item={item}
            index={index}
            restaurantID={props.restaurantID}
          />
        ))}
        <div className="cancel-btn">
          <button className="cancel-btn" onClick={handleDeleteCommand}>
            <img src={Bin} alt="" />
            Annuler commande
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurantId: state.menu.activeRestaurantId,
    preSavedCart: state.cart.preSavedCart,
    savedCategories: state.cart.savedCategories,
    menuId: state.menu.menuId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      category: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(category, menuId, restaurantId)),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartElements);
