import { userInfo } from 'os';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: [],
  sendVerificationCodeResponse: null,
  resendVerificationCodeResponse: null,
  validationStepToken: null,
  validationSignupToken: null,
  token: null,
  verifyCodeSuccess: null,
  userSignUpSuccess: null,
  authRetries: 0,
  userMode: 0,
  authError: null,
  location: null,
  ipGeo: null,
  userInfo: null,
  editProfileSucessRetries: 0,
  errorMessage:null,
  userFavorites: null,
  authGoogleSuccess: null,
  authAppleSuccess: null,
  auth3dthird: null,
  userRedirectAfterLoginTo:null
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_USER_LOCATION:
      {
        return {
          ...state,
          location: action.location,
        };
      }

    case actionTypes.UPDATE_TOKENS:
      {
        return {
          ...state,
          validationStepToken: action.verifToken,
         validationSignupToken: action.signupToken,
          token: action.token
        }
      }

    case actionTypes.SET_IP_GEO:
      {
        return {
          ...state,
          ipGeo: action.location,
        };
      }

    case actionTypes.UPDATE_USER_AUTH:
      return {
        ...state,
        user: { ...state.user, ...action.userInfo },
        userInfo: { ...state.user, ...action.userInfo },
      }

    case actionTypes.VERIFY_GOOGLE_SUCCESS:
      return {
        ...state,
        authGoogleSuccess: action.user,
      }

    case actionTypes.VERIFY_GOOGLE:
      return {
        ...state,
        authGoogleSuccess: null,
      }

    case actionTypes.VERIFY_APPLE:
      return {
        ...state,
        authAppleSuccess: null,
      }

    case actionTypes.VERIFY_APPLE_SUCCESS:
        return {
          ...state,
          authAppleSuccess: action.user,
        }

    case actionTypes.SEND_VERFICATION_CODE_SUCCESS:
      return {
        ...state,
        sendVerificationCodeResponse: action.response,
        authRetries: state.authRetries + 1
      }

    case actionTypes.RESEND_VERFICATION_CODE_SUCCESS:
      return {
        ...state,
        resendVerificationCodeResponse: action.response,
        authRetries: state.authRetries + 1
      }

    case actionTypes.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        verifyCodeSuccess: action.response,
        userInfo: action.response?.user,
        validationSignupToken: action.response?.validationSignupToken,
      }

    case actionTypes.CLEAR_STORE_ITEM:
      {
        var key = action?.item;
        var obj: any = {};
        obj[key] = null;
        
        return {
          ...state,
          ...obj
        };
      }

    case actionTypes.SINGUP_SUCCESS:
      return {
        ...state,
        userSignUpSuccess: action.response,
        authRetries: state.authRetries + 1,
       userInfo:action.response.user
      }

    case actionTypes.SET_USER_MODE:
      return {
        ...state,
        userMode: action.userMode,
      }

    case actionTypes.AUTH_ERROR:
      console.log("AUTH_ERROR",action)
      return {
        ...state,
        authError: action.error,
        authRetries: state.authRetries + 1
      }

    case actionTypes.RESET_USER:
      return {
        ...state,
        user: [],
        sendVerificationCodeResponse: null,
        resendVerificationCodeResponse: null,
        verifyCodeSuccess: null,
        userSignUpSuccess: null,
        authRetries: 0,
        userMode: 0,
        authError: null
      }
      case actionTypes.AUTH_ERROR_EDIT:
        return {
          ...state,
          authErrorEdit: action.error,
          authRetries: state.authRetries + 1
  
        }
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        user: [],
        sendVerificationCodeResponse: null,
        resendVerificationCodeResponse: null,
        verifyCodeSuccess: null,
        userSignUpSuccess: null,
        authRetries: 0,
        userMode: 0,
        authError: null,
        token: null,
        userInfo:null,
      }

    case actionTypes.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,

        userInfo: action.user,
      }

    case actionTypes.EDIT_MY_PROFILE_SUCCESS:
      {
        return {
          ...state,
          userInfo: action.user,
          editProfileSucessRetries: state.editProfileSucessRetries + 1
        };
      }
      
    case actionTypes.EDIT_MY_PROFILE_FAILURE:
      {
        return {
          ...state,
          errorMessage: action.errorMessage,
          
        };
      }

    case actionTypes.EDIT_MY_PROFILE_SUCCESS_RESET:
      {
        return {
          ...state,
          userInfo: action.user,
          editProfileSucessRetries: state.editProfileSucessRetries + 1
        };
      }
    case actionTypes.USER_REDIRECT_AFTER_LOGIN_TO:
        {
          return {
            ...state,
            userRedirectAfterLoginTo: action?.value
          };
        }

    case actionTypes.GET_FAVORITES_FILTRED_SHOP_SUCCESS:
      {
        return {
          ...state,
          userFavorites: action.restaurants,
        };
      }

    case actionTypes.UPDATE_3D_THIRD_AUTH:
      {
        return {
          ...state,
          auth3dthird: { ...action.body },
        };
      }

    default:
      return state;
  }
}

export default reducer;