import React, { useEffect, useRef } from "react";

interface LoaderProps {
  loadingTimeout: number;
}

const Loader: React.FC<LoaderProps> = ({ loadingTimeout }) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loader = loaderRef.current;
    
    if (loader) {
      const timeoutId = setTimeout(() => {
        addDoneClass(loader);
      }, loadingTimeout);

      return () => clearTimeout(timeoutId);
    }
  }, [loadingTimeout]);

  const addDoneClass = (target: HTMLDivElement) => {
    target.classList.add("done");
  };

  return (
    <div ref={loaderRef} className="loader">
      <svg className="SVGloader" viewBox="25 25 50 50">
        <circle
          className="loader__background"
          cx="0"
          cy="0"
          r="45"
          transform="translate(50 50)"
          strokeWidth="3"
        />
        <circle
          className="loader__rotation"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
        />
        <path
          className="loader__path"
          d="m48,58l11,-16"
          strokeDasharray="23"
          strokeDashoffset="23"
        />
        <path
          className="loader__path"
          d="m48,58l-8,-6"
          strokeDasharray="10"
          strokeDashoffset="10"
        />
      </svg>
    </div>
  );
};

export default Loader;
