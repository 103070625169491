import React from "react";
import { generateSupplementsString } from "../../../../utils/compositionToString";

export default function ListSubMenuElement({
  compo,
}: {
  compo: serviceDetailsOrderModels.Component;
}) {
  return (
    <div className="ListSubMenuElement">
      <p className="SubMenuNameLabel">{compo.name}</p>
      <p className="SubMenuNamevalue">{compo.name}</p>
      {compo.compositions.length>0 && (
      
      <p className="SubMenuNamelist">
       {generateSupplementsString(compo.compositions)}
        </p>
      )}
     
    </div>
  );
}
