import React, { useEffect, useState } from "react";
import TrashOrange from "../../../images/trashOrange.svg";
import PlusboxOrange from "../../../images/plusboxOrange.svg";
import MinusboxOrange from "../../../images/MinusOrange.svg";
import foodImg from "../../../images/food.png";
import { useParams } from "react-router-dom";
import {
  pushCategory,
  resetCategory,
  updateCategoryCount,
  updateCompositionsPrice,
  updateCurrentPrice,
  updateOrder,
} from "../../../store/actions/actions";
import { connect } from "react-redux";
import withNavigateHook from "../../WithNavigation";

const CartSubElements = (props: any) => {
  const [categoryCount, setcategoryCount] = useState<number>(
    props.item?.quantity
  );


  const theIdIs = parseInt(props.URLParams.id)
    ? parseInt(props.URLParams.id)
    : props.restaurantID?.id;

  useEffect(() => {
    // Code to refresh the component (e.g., fetch updated data, re-render, etc.)
    console.log("Component refreshed");
  }, [categoryCount]);

  const __updateOrder = (
    i: number,
    type: string,
    index: number,
    categoryId?: number,
    menuId?: number,
    selectedCategory?: serviceMenuModels.Category,
    deleteRestaurant?: boolean
  ) => {
    let curreSavedCart = props.savedCategories;
    let indexOfCategory = i;
    let category: serviceMenuModels.Category =
      curreSavedCart[index]?.categories[indexOfCategory];
    if (type == "INCREASE") {
      //  let categories = curreSavedCart[index].menu.menus[menuId].categories?.filter((cat: serviceMenuModels.Category) => cat.id == categoryId)
      category.quantity = (category?.quantity || 0) + 1;
      setcategoryCount((prevCount) => prevCount + 1);
      //   category.amount = category.amount + category.price
      curreSavedCart[index].categories[indexOfCategory] = category;
      props.updateOrder(curreSavedCart);
    } else if (type == "DECREASE") {
      // let categories = curreSavedCart[index].menu.menus[menuId].categories?.filter((cat: serviceMenuModels.Category) => cat.id == categoryId)
      //let indexOfCategory = selectedCategory
      //    let category: serviceMenuModels.Category = curreSavedCart[index].menu.menus[menuId].categories[indexOfCategory]
      if (category?.quantity && category.quantity > 1) {
        let category: serviceMenuModels.Category =
          curreSavedCart[index]?.categories[indexOfCategory];
        category.quantity -= 1;
        setcategoryCount((prevCount) => prevCount - 1);
        //   category.amount = category.amount + category.price
        curreSavedCart[index].categories[indexOfCategory] = category;
        props.updateOrder(curreSavedCart);
      } else {
        if (deleteRestaurant) {
          delete curreSavedCart[index];
        } else if (category.quantity - 1 <= 0) {
          let cats = curreSavedCart[index].categories;

          cats?.splice(indexOfCategory, 1);
          curreSavedCart[index].categories = cats;
          let selectedCategories = curreSavedCart[index].categories;

          if (selectedCategories.length == 0) delete curreSavedCart[index];
        }
      }
      props.updateOrder(curreSavedCart);
    }
  };

  return (
    <div className="menuElement animated animatedFadeInUp fadeInUp">
      <div className="menu-header">
        <div className="col-1-header">
          <p className="menu-name">{props.item?.name}</p>
          <img
            className="menu-img"
            src={props.item?.image}
            alt={props.item?.name}
          />
        </div>
        <div className="col-2-header">
          <div className="slot-menu">
            <img
              className=""
              src={categoryCount === 1 ? TrashOrange : MinusboxOrange}
              alt=""
              onClick={() => __updateOrder(props.index, "DECREASE", theIdIs)}
            />
            <p className="quantity">{props.item.quantity}</p>
            <img
              className=""
              src={PlusboxOrange}
              alt=""
              onClick={() => __updateOrder(props.index, "INCREASE", theIdIs)}
            />
          </div>
          <div className="price">
            <p>
              {props.item.products === null
                ? (props.item?.price * props.item.quantity).toFixed(2)
                : (props.item?.amount * props.item.quantity).toFixed(2)}
              <span> €</span>
            </p>
          </div>
        </div>
      </div>
      {props.item?.products &&
        props.item?.products?.map((product: any) => {
          if (product?.numberOfSelection > 0) {
            return (
              <div className="menu-content">
                <div className="menu-content-wrapper">
                  <div className="menu-list">
                    <p className="menuItemName">{product?.name}</p>

                    {product?.components?.map((component: any) => {
                      if (component?.numberOfSelection > 0) {
                        return (
                          <div key={component.id} className="menusItemList">
                            <div className="SingleMenuList">
                              <p className="menuSubName">
                                {component?.numberOfSelection} {component?.name}
                              </p>
                              {component.description ? (
                                <p className="SubitemDescription">
                                  {component.description
                                    ? component.description
                                    : null}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>

                  {/* <div className="menu-list">
              <p className="menuItemName">Choix Taille</p>
              <div className="SingleMenuList">
                <p className="itemDescription">Taille M</p>
              </div>
            </div>
            <div className="menu-list">
              <p className="menuItemName">Condiments</p>
              <div className="SingleMenuList">
                <p className="itemDescription">
                  Poivrons, Olives, Artichauts (+2,00€)
                </p>
              </div>
            </div>
            <div className="menu-list">
              <p className="menuItemName">Boissons</p>
              <div className="SingleMenuList">
                <p className="itemDescription">Coca cola, Eau 50cl</p>
              </div>
            </div>
            <div className="menu-list">
              <p className="menuItemName">Desserts</p>
              <div className="SingleMenuList">
                <p className="itemDescription">Tiramisu, CheeseCake</p>
              </div>
            </div> */}
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    selectedCategory: state.menu.selectedCategory,
    menuId: state.menu.menuId,
    currentPrice: state.cart.currentPrice,
    compositionsPrice: state.cart.compositionsPrice,
    categoryBasePrice: state.cart.categoryBasePrice,
    menu: state.menu.menu,
    initialMenu: state.menu.initialMenu,
    preSavedCart: state.cart.preSavedCart,
    savedCart: state.cart.savedCart,
    categoryCount: state.cart.categoryCount,
    mode: state.menu.mode,
    priceChangeCount: state.cart.priceChangeCount,
    savedCategories: state.cart.savedCategories,
    activeRestaurantId: state.menu.activeRestaurantId,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
    updateOrder: (obj: any) => dispatch(updateOrder(obj)),
    resetCategory: () => dispatch(resetCategory()),
    updateCategoryCount: (type: string) => dispatch(updateCategoryCount(type)),
    updateCurrentPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCurrentPrice(newPrice, actionType)),

    updateCompositionsPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCompositionsPrice(newPrice, actionType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(CartSubElements));
