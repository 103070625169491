import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import dayjs from 'dayjs';

import {
  clearStoreItem,
  confirmPaymentAction,
  createPaymentAction,
  updateSavedCart,
} from "../../store/actions/actions";
import { createPaymentMapper } from "../../mapper/orderMapper";
import { savedCartData } from "../../data/savedCategorieData";
import { languageData } from "../../i18n";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { orderApi, paymentApi } from "../../services/apiGate";
import { PaymentIntentResult } from "@stripe/stripe-js";
import Loader from "./ProcessingCommand/Loader";
import ErrorIcon from "../../images/ErrorIcon.svg";
import ProcessingCommand from "./ProcessingCommand/ProcessingCommand";
import { Navigate, useNavigate } from "react-router-dom";
import InvoiceCommand from "./ProcessingCommand/InvoiceCommand";

function CheckoutForm(props: any) {
 
  const stripe = useStripe();
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);
  const elements = useElements();
  useEffect(() => {
    seterror("");
  }, [props.isOnlinePaymentChecked]);
  // const [isOnlinePaymentChecked, setOnlinePaymentChecked] = useState(false);
  // const [showProcessingCommand, setShowProcessingCommand] = useState(false);
  const [objectcategory, setObjectCategory] = useState(
    localStorage.getItem("resturantId")
  );
  



  const selectedTimeRightISO = (timeString:any) => {
    if (!timeString) return null;
    const todayDate = dayjs().format("YYYY-MM-DD");
    const combinedDateTimeString = `${todayDate}T${timeString}`;
    const dateTime = dayjs(combinedDateTimeString);
    return combinedDateTimeString;
  };
  const ___handleCreatePayment = async (payId: any, payLater?: boolean) => {
    try {
      //   setloading(true);

      const totalPrice = props.savedCategories[
        objectcategory as string
      ]?.categories?.reduce((total = 0, category: any) => {
        return total + category?.amount * category?.quantity;
      }, 0);
    
      let createPaymentBody = await createPaymentMapper({
        categories: props.savedCategories[objectcategory as string]?.categories,
        restaurant: props.savedCategories[objectcategory as string]?.restaurant,
        mode: props?.categoryShopMode,
        shownAddress: props.location.shownAddress,
        addressGeo: props.location.addressGeo,
        menuId: props.savedCategories[objectcategory as string]?.menuId,
        totalPrice: totalPrice,
        user: props.userInfo,
        paymentMethodId: "" + payId,
        preparedDate: props.isMaintenantChecked ?  selectedTimeRightISO(props.selectedTimeRight)  :null,
        payLater: payLater,
      });

      
      props.createPayment(createPaymentBody);
    } catch (error) {
      setloading(false);

      console.log({ rro2: error });
    }

    //  navigate('PaymentError', null, false)
  };

  const handleSubmit = async (event: any) => {
    seterror("");
    if (props.isOnlinePaymentChecked) {
      const cardElementt: StripeCardNumberElement | null | undefined =
        elements?.getElement(CardNumberElement);
      event.preventDefault();

      if (!stripe || !elements ) {
        return;
      }
   
      if (cardElementt) {
        window.scrollTo(0, 0);
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElementt,
          })
          .then(function (result) {
          
            if (!result.error) {
              setloading(true);
              ___handleCreatePayment(result.paymentMethod?.id);
            }
            if (result.error) {
              setloading(false);
              seterror("Votre numéro de carte est incomplet." ?? "");
            }
          })
          .catch((error) => {
            setloading(false);
            console.log(error);
          });
      }
    } else {
      setloading(true);
      ___handleCreatePayment(null, true);
    }
  };

  const ShowInvoice = () => {
    document
      .querySelector(".InvoiceCommandComponent")
      ?.classList.add("DisplayInvoice");
  };

  const confirmpay = async (sc: string, commandId: number) => {
    try {
      const resposne = await stripe?.confirmCardPayment(sc);
   
      let paymentIntent = resposne?.paymentIntent;
      let error = resposne?.error;
      if (paymentIntent)
        if (paymentIntent) {
          // console.log(paymentIntent?.status);
          let response = await paymentApi.confirmPayment({
            commandId: commandId,
            paymentIntentId: paymentIntent?.id,
            idempotencyKey: uuidv4(),
            codePromo: null,
            status: paymentIntent?.status ?? "canceled",
          });
          window.scrollTo(0, 0);
          document
            .querySelector(".paimentSection")
            ?.classList.add("slideUpHide");
          props.setShowProcessingCommand();
          setTimeout(() => {
            window.location.href = "#/mes-commandes";
          }, 6000);
        }

      // navigate('PaymentSuccess', { order: { ...order } }, false)

      //  __cancelOrder(restaurantId)
    } catch (error) {
      setloading(false);
      seterror("Echec de paiement");
      console.log("error confir pay", error);
    }
  };
  const fetchOrderDetails = async (id: string) => {
    try {
      let response = await orderApi.getOrderById(id);
      let orderDetails = response.data;
      if (orderDetails) {
        props.setcommand(orderDetails?.commande);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    try {
      if (props.payementSuccess === true) {
        window.scrollTo(0, 0);
        document.querySelector(".paimentSection")?.classList.add("slideUpHide");
        document
          .querySelector(".InvoiceCommandComponent")
          ?.classList.add("DisplayInvoice");
        props.setShowProcessingCommand();
        props.setpromptCommand(true);
        props.setqrcode(props.payementResponse?.QrCode);

      
        setTimeout(() => {
          setTimeout(() => {
            document
              .querySelector(".InvoiceCommandComponent")
              ?.classList.add("DisplayInvoice");
            fetchOrderDetails("" + props.payementResponse?.commandId);
          }, 1000);

          //window.location.href = "#/mes-commandes";
        }, 6000);
        //  navigate('PaymentSuccess', {order: {...order}}, false);

        props.clearItemStore("payementSuccess");
        props.clearItemStore("payementResponse");
      } else if (props.payementSuccess === false) {
        if (props.payementResponse?.status == "requires_action")
          confirmpay(
            props.payementResponse?.clientSecret,
            props.payementResponse?.commandId
          );
        else {
          if (props.payementResponse?.error) {
            setloading(false);
            seterror(props.payementResponse?.error);
            /* navigate(
              "PaymentError",
              { order: { ...order }, error: props.payementResponse?.error },
              false
            ); */
          }
        }

        //
      }
    } catch (error) {}
    return () => {
      props.clearItemStore("payementSuccess");
    };
  }, [props.payementSuccess, props.payRetries]);
  // console.log({props})

  return (
    <div className="cardContainer">
      <div className={loading ? "OverylayLoading" : ""}></div>
      {props.isOnlinePaymentChecked && (
        <>
          <div className="card-number card-info">
            <CardNumberElement options={{ showIcon: true }}></CardNumberElement>
          </div>
          <div style={{ display: "flex", gap: "20px" }} className="">
            <div className="card-info" style={{ flex: "1" }}>
              <CardExpiryElement></CardExpiryElement>
            </div>
            <div className="card-info" style={{ flex: "1" }}>
              <CardCvcElement></CardCvcElement>
            </div>
          </div>
          {error && (
            <p className="msg_error">
              <img src={ErrorIcon} alt="" />
              {error}
            </p>
          )}
        </>
      )}
      {!props.isOnlinePaymentChecked && error && (
        <p className="msg_error">
          <img src={ErrorIcon} alt="" />
          {error}
        </p>
      )}
      {/* <div className="submit-order">
        <button onClick={handleSubmit}>
          {loading ? "Chargement ..."  : languageData.fr.payment.btnText}
        </button>
      </div> */}
      <div className="submit-order">
        {loading ? (
          <div className="BtnLoader">
            <div className="disabledClass"></div>
            <button onClick={handleSubmit}>
              <p>Chargement</p>
              <div style={{ transform: "translateX(30px)" }}>
                <div className="loaderBtn"></div>
              </div>
            </button>
          </div>
        ) : (
          <span>
            <button disabled={!props.isValidTime} onClick={handleSubmit}>
              <p>{languageData.fr.payment.btnText}</p>
            </button>
          </span>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    selectedProductWithComposition: state.menu.selectedProductWithComposition,
    selectedCategory: state.menu.selectedCategory,
    menuId: state.menu.menuId,
    currentPrice: state.cart.currentPrice,
    menu: state.menu.menu,
    preSavedCart: state.cart.preSavedCart,
    productHasCompositionId: state.menu.productHasCompositionId,
    componentId: state.menu.componentId,
    compositionsPrice: state.cart.compositionsPrice,
    selectedOrder: state.order.selectedOrder,
    userInfo: state.auth.userInfo,
    savedCategories: state.cart.savedCategories,
    location: state.auth.location,
    payementSuccess: state.payment.payementSuccess,
    payRetries: state.payment.payRetries,
    payementResponse: state.payment.payementResponse,
    categoryShopMode: state.category.categoryShopMode,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    confirmPayment: (order: any) => dispatch(confirmPaymentAction(order)),
    createPayment: (order: any) => dispatch(createPaymentAction(order)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateSavedCart: (obj: any) => dispatch(updateSavedCart(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
