import * as actionTypes from "../actions/actionTypes";

const initialState = {
  categories: null,
  currentCategory: [],
  savedCurrentCategory: [],
  categoriesLoading: false,
  shopsPerCategory: null,
  categoryShopListLoading: false,
  categoryShopMode: 0,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categories: action.categoriesList,
      };

    case actionTypes.UPDATE_CATEGORY_LOADING:
      return {
        ...state,
        categoriesLoading: action.value,
      };

    case actionTypes.GET_CATEGORY_SHOP_LIST:
      return {
        ...state,
        categoryShopListLoading: true,
        shopsPerCategory: null,
      };
    case actionTypes.CLEAR_STORE_ITEM: {
      var key = action?.item;
      var obj: any = {};
      obj[key] = null;

      return {
        ...state,
        ...obj,
      };
    }

    case actionTypes.GET_CATEGORY_SHOP_LIST_SUCCESS:
      return {
        ...state,
        categoryShopListLoading: false,
        shopsPerCategory: action.list,
      };

    case actionTypes.CHANGE_CATEGORY_SHOP_MODE:
      return {
        ...state,
        categoryShopMode: action.value,
        categoryShopListLoading: true,
      };

    case actionTypes.CHANGE_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.value,
        savedCurrentCategory: action.value,
      };

    default:
      return state;
  }
}

export default reducer;
