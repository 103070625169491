interface Composition {
  id: number;
  name: string;
  image: string;
  price: number;
  isDefault: boolean;
  maxSelection: number;
  hasAllergene: boolean;
  allergenes: any; // You can specify the type of allergenes if needed
}

export const generateSupplementsString = (
  compositions: Composition[]
): string => {
  const supplements: string[] = [];

  for (const composition of compositions) {
    if (composition.price !== 0) {
      supplements.push(
        `${composition.name} (${composition.price.toFixed(2)}€)`
      );
    } else {
      supplements.push(composition.name);
    }
  }

  return `Suppléments : ${supplements.join(", ")}`;
};
