import React, { useEffect, useState } from "react";
import PanierIcon from "../../images/shopping-bag-01.svg";
import { useNavigate } from "react-router-dom";
import { useCallback } from 'react';
import pizza01 from "./pizza02.jpg";
import ShowMore from "../../images/right-arrow-orange.svg";
import EmptyCart from "../../images/empty-cartIcon.svg";
import CartNav from "./Cart/Cart";
import CartData from "./Cart/CartData";
import "./panier.scss";
import { connect } from "react-redux";
import withNavigateHook from "../WithNavigation";
import { config } from "../../config";
import AuthLoginModal from "./Cart/modals/AuthLoginModal";
import CloseIcon from "../../images/CloseIcon_btn.svg";
import RestaurantIcon from "../../images/RestaurantIcon.svg";
import { languageData } from "./../../i18n/index";
import { shopApi } from "../../services/apiGate";
const api_baseUrl_Picture = config.api_baseUrl_Picture;
const Panier = (props: any) => {
  const navigate = useNavigate();
  const [show_panier, setShowPanier] = useState(false);
  const [PanierVide, SetPanierVide] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [category, setCategory] = useState<any>();
  const [restaurantID, setRestaurantID] = useState<any>();
  const [showAuth, setShowAuth] = useState(false);
  const [ShowCommandDetails, setShowCommandDetails] = useState(false);
  const [ShowRestaurantError, setShowRestaurantError] = useState(false);

  const [blockBody, setblockBody] = useState(false);

  const handleClicke = (id: any) => {
    navigate(`/restaurant/${id}`);
  };
  const savedCategoriesArray = Object.values(props.savedCategories);
  console.log("savedCategoriesArray", savedCategoriesArray);
  useEffect(() => {
    if (blockBody) {
      document.querySelector("body")?.classList.add("disable-scroll");
    } else {
      document.querySelector("body")?.classList.remove("disable-scroll");
    }
  }, [blockBody]);
  const handelBodyScroll = () => {
    setblockBody((prevblockBody) => !prevblockBody);
  };
  const handleClickes = (index: any, item: any) => {
    setblockBody(true);
    document.querySelector(".panier_box")?.classList.remove("showBox");
    // document.querySelector(".CommandInfo")?.classList.add("showCommandDetails");
    setShowCommandDetails(!ShowCommandDetails);
    localStorage.setItem("resturantId", item?.restaurant?.id);
    setCategory(item);
    setRestaurantID(item.restaurant);
  };

  const totalAmount = category?.categories
    ?.reduce(
      (acc: number, curr: any) => (acc += curr?.amount * curr?.quantity),
      0
    )
    .toFixed(2);

  const totalQuantities = savedCategoriesArray?.map(
    (item: any) =>
      item?.categories?.reduce(
        (acc: any, curr: any) => (acc += curr?.quantity),
        0
      ) ?? 0
  );

  const totalQuantitySum: number = totalQuantities.reduce(
    (acc: number, curr: number) => acc + curr,
    0
  );
  const checkRestaurantDisponibilty = async (id: number) => {
    const restaurantApi = await shopApi.getResturantById({
      id: id,
      diningMode: props.mode,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    });
    return (
      restaurantApi.data?.restaurant?.isEnabled &&
      restaurantApi.data?.restaurant?.isOpen
    );
  };
 
  const handleCommand = async (id: number) => {
    let disponible = await checkRestaurantDisponibilty(id);
    if (disponible) {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        localStorage.setItem("redirectedToAuth", "true");
        navigate("/Authentification");
      } else {
        localStorage.setItem("redirectedToAuth", "false");
        navigate("/paiement");
      }
    } else {
      setShowRestaurantError(!ShowRestaurantError);
    }
  };

  return (
    <div className={`panier_box ${show_panier ? "showBox" : ""}`}>
      <div
        className="panier-btn"
        onClick={() => {
          setShowPanier(!show_panier);
          handelBodyScroll();
        }}
      >
        <div className="panier-el">
          {/* <div style={{display: "flex", gap: "2px", justifyContent: "center",alignItems: "center"}}> */}
          <img src={PanierIcon} alt="" />
          <p className="alias-panier">Panier</p>
          <b style={{ color: "white" }}>•</b>
        </div>
        <p className="products-count">{savedCategoriesArray.length}</p>
      </div>
      {/* cut here  */}
      <div className="panier-content-box ">
        <div
          onClick={() => {
            setShowPanier(!show_panier);
            handelBodyScroll();
          }}
          className="overlay-box"
        ></div>
        <div
          onClick={() => SetPanierVide(!PanierVide)}
          className={`panier-data ${savedCategoriesArray.length <= 0 ? "hide-el" : ""}`}
        >
          {savedCategoriesArray?.map((item: any, index: number) => {
            return (
              <div className="hovering" key={index}>
                <div className="items-list ">
                  <div
                    className="items-wrapper"
                    onClick={() => {
                      handleClickes(index, item);
                    }}
                  >
                    <div className="left-side-item">
                      <img
                        src={`${api_baseUrl_Picture}/${item?.restaurant?.imageUrl}`}
                        alt={item?.restaurant?.nom}
                      />
                      {/* <p>{"Restaurant " + index}</p> */}
                    </div>
                    <div className="item-info">
                      <div className="item-info-wrapper">
                        <div className="item-details">
                          <div className="product">
                            <p className="product-name">
                              {item?.restaurant?.nom}
                            </p>
                            <p className="quantity-count">
                              {item?.categories?.reduce(
                                (acc: number, curr: any) =>
                                  acc + curr?.quantity,
                                0
                              )}
                            </p>
                          </div>
                          <div className="sub-description">
                            <p className="sous-total">
                              {" "}
                              Sous-Total :{" "}
                              {item?.categories
                                ?.reduce(
                                  (acc: number, curr: any) =>
                                    (acc += curr?.amount * curr?.quantity),
                                  0
                                )
                                .toFixed(2)}
                              €{" "}
                            </p>
                            <p className="adresse">
                              {" "}
                              {item?.mode == 0
                                ? "A Emporter à :"
                                : "Sur Place"}{" "}
                              {item?.item == 0
                                ? props.location?.shownAddress
                                : ""}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="show-item">
                          <img src={ShowMore} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          onClick={() => SetPanierVide(!PanierVide)}
          className={`empty-panier ${savedCategoriesArray.length === 0 ? " " : "hide-el"}`}
        >
          <div className="panier-wrapper">
            <img src={EmptyCart} alt="EmptyCart" />
            <p>Aucun Article ajouté</p>
            <span>Ajoutez des articles, ils seront affichés ici.</span>
          </div>
        </div>
      </div>

      <div
        className={`CommandInfo CartNav ${ShowCommandDetails ? "showCommandDetails" : ""}`}
      >
        <div
          onClick={() => {
            setShowCommandDetails(!ShowCommandDetails);
            handelBodyScroll();
          }}
          className="overlay-box"
        ></div>
        {savedCategoriesArray.length > 0 ? (
          <CartData
            handleCloseIconExit={()=>localStorage.setItem("redirectedToPrevPage", "false")}
            setShowCommandDetails={setShowCommandDetails}
            ShowCommandDetails={ShowCommandDetails}
            category={category}
            restaurantID={restaurantID}
            totalAmount={totalAmount}
            navigateToShope={() => handleClicke(restaurantID?.id)}
            handleCommand={handleCommand}
          />
        ) : null}
      </div>
      <div className={`restaurantError ${ShowRestaurantError ? "" : "hide"}`}>
        <div
          className="overlay-modal"
          onClick={() => setShowRestaurantError(!ShowRestaurantError)}
        ></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt=""
              onClick={() => {setShowRestaurantError(!ShowRestaurantError);handelBodyScroll()}}
            />
            <div>
              <p className="modalTitle">
                {languageData.fr.ModalRestaurantError.modalTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="" />
                <p className="modalDescription">
                  {languageData.fr.ModalRestaurantError.ModalDescription}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            >
              {languageData.fr.ModalRestaurantError.ModalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    homeLoading: state.shop.homeLoading,
    shops: state.shop.shops,
    shopMode: state.shop.mode,
    currentCategory: state.category.currentCategory,
    restaurantNameFilter: state.shop.restaurantNameFilter,
    cartList: state.order.cartList,
    cartListById: state.order.cartListById,
    savedCategories: state.cart.savedCategories,
    userInfo: state.auth.userInfo,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    categoryShopMode: state.category.categoryShopMode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // logout: () => dispatch(logout()),
    // clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    // updateUserLocation: (location: any) =>
    //   dispatch(updateUserLocation(location)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Panier));
