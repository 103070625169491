import React from "react";
import SubMenuElement from "./SubMenuElement";

export default function MenuSection({
  detail,
}: {
  detail: serviceDetailsOrderModels.DetailCommande;
}) {
  return (
    <div className="menuSection">
      <div className="menu-header">
        <div>
          <p className="menu-count">{detail.quantity}</p>
          <p className="menu-name">{detail.name}</p>
        </div>
        <p className="menu-price">{detail.totalCost.toFixed(2)}€</p>
      </div>
      <div className="menus-elements">
        {detail?.components?.map((el) => <SubMenuElement compo={el} />)}
      </div>
    </div>
  );
}
