import { v4 as uuidv4 } from "uuid";

import { generateCommand } from "./commandHelper";
export function calculatePriceForUnpairedQuantity(
  quantity: number,
  pricePerUnit: number
) {
  if (quantity % 2 === 0) {
    const pairedQuantity = Math.floor(quantity / 2);
    return pricePerUnit * pairedQuantity;
  } else {
    // Quantity is odd, calculate price for unpaired item
    const unpairedQuantity = Math.floor(quantity / 2) + 1;
    const totalPrice = unpairedQuantity * pricePerUnit;
    return totalPrice;
  }
}
type props = {
  order: serviceCommandModels.Commande;
};
export const orderMapper = async ({ order }: props) => {
  let sectionMenu = await Promise.all(
    order.detailCommande?.map((menu: any, index) => {
      return {
        title: menu.name,
        data: menu.categories == null ? [] : menu.categories,
      };
    })
  );

  return sectionMenu;
};
type order = {
  categories: serviceMenuModels.Category[];
  restaurant: serviceMenuModels.Restaurant;

  mode: number;
  shownAddress: string;
  menuId: number;
  totalPrice: number;
  user: serviceAuthModels.user;
  paymentMethodId: string;
  addressGeo: any;
  preparedDate: any;
  payLater?: boolean;
};
export const createPaymentMapper = async (order: order) => {
  let restaurant = order.restaurant;

  let orderSummary: any[] = [];
  let orderDetailed: any[] = [];
  let selectedCategories = order.categories;

  let totalQuantity = 0;
  await Promise.all(
    selectedCategories.map(async (selectedCategory) => {
      /*  setTimeout(() => {
            
         }, 3000); */
      totalQuantity = totalQuantity + selectedCategory.quantity;
      let obj = {
        item: "",
        quantity: 0,
        unitPrice: 0,
        amount: 0,
      };
      obj.item = selectedCategory.name;
      obj.quantity = selectedCategory.quantity;
      obj.amount = selectedCategory?.ofIsApply
        ? calculatePriceForUnpairedQuantity(
            selectedCategory.quantity,
            selectedCategory.amount
          )
        : selectedCategory.amount * 1;
      obj.unitPrice = selectedCategory.amount;
      let products: serviceMenuModels.Product[] = selectedCategory?.products
        ? selectedCategory.products
        : [];
      let p = {};
      let comps: serviceMenuModels.Component[] = [];
      if (products.length > 0)
        await Promise.all(
          products.map(async (el: serviceMenuModels.Product) => {
            if (el?.numberOfSelection > 0) {
              await Promise.all(
                el.components?.map((comp) => {
                  if (comp.numberOfSelection > 0) {
                    let compositionArray: serviceMenuModels.Composition[] = [];
                    comp.compositions?.map((cc) => {
                      if (cc.numberOfSelection && !cc?.defaultHidden) {
                        compositionArray.push(cc);
                      }
                    });
                    comps.push({
                      ...comp,
                      quantity: comp.numberOfSelection,
                      compositions: compositionArray,
                    });
                  }
                })
              );
              p = el;
              setTimeout(() => {}, 3000);

              let family = restaurant?.menus?.find(
                (m) => m.id == selectedCategory.menuId
              );
              console.log(family);
              p = {
                ...p,
                quantity: selectedCategory.quantity,
                id: "" + uuidv4(),
                productId: selectedCategory.id,
                restaurantId: restaurant.id,
                complete: selectedCategory.loyaltyPointsProd > 0 ? true : true,
                family: family ?? restaurant?.menus[selectedCategory.menuId],
                menuNode: { ...selectedCategory },
                totalCost: selectedCategory.amount * selectedCategory.quantity,
                components: [...comps],
                name: selectedCategory.name,
              };
            }
          })
        );
      else {
        let family = restaurant?.menus?.find(
          (m) => m.id == selectedCategory.menuId
        );
        console.log(
          "restaurant?.menus[order.menuId]",
          selectedCategory.menuId,
          restaurant?.menus[selectedCategory.menuId]
        );
        p = {
          ...p,
          quantity: selectedCategory.quantity ?? 1,
          id: "" + uuidv4(),

          productId: selectedCategory.id,
          restaurantId: restaurant.id,
          complete: selectedCategory.loyaltyPointsProd > 0 ? true : true,
          family: family ?? restaurant?.menus[selectedCategory.menuId],
          menuNode: { ...selectedCategory },
          totalCost: selectedCategory.amount * selectedCategory.quantity,
          components: [],
          name: selectedCategory.name,
        };
      }

      orderSummary.push(obj);
      orderDetailed.push(p);
    })
  );

  let orderCmd = {
    cart: order.categories,
    restaurant: order.restaurant,
    remise: null,
    totalCost: order.totalPrice,
    totalPoints: 0,
    totalPointsApplyed: 0,
    diningMode: order.mode,
    client: order.user,
    address: null,
    decalage: 0,
  };
  const getTotalPoints = (cats: serviceMenuModels.Category[]) => {
    let totalPoints = 0;
    cats?.map(async (cat) => {
      totalPoints = totalPoints + cat.loyaltyPointsProd;
      cat.products?.map(async (product) => {
        await Promise.all(
          product.components?.map(async (compo) => {
            //   totalPoints = totalPoints + compo.loyaltyPointsProd
          })
        );
      });
    });
    return totalPoints;
  };
  console.log("orderorderorderprops", order);
  let cmd = generateCommand(
    order.categories,
    order.restaurant,
    null,
    order.totalPrice,
    getTotalPoints(order.categories),
    0,
    order.mode,
    order.user,
    order.addressGeo,
    order.preparedDate
  );
  let payment_body: any = {};
  payment_body = {
    ...payment_body,
    firstName: order.user.firstName,
    lastName: order.user.lastName,
    email: order.user.email,
    phone: order.user.phoneNumber,

    restaurantId: order.restaurant.id,
    orderSummary: orderSummary,
    orderDetailed: "" + JSON.stringify(orderDetailed),

    remise: "null",
    payLater: order.payLater ? true : false,
    address: "Test Address",
    idempotencyKey: "" + uuidv4(),
    loyaltySecretKey: "",
    qrCode: "",
    paymentMethodId: order.paymentMethodId,
    codePromo: "",
    diningMode: order.mode == 0 ? "takeaway" : "onspot",

    command: "" + JSON.stringify(cmd),
    PrepareFor: order.preparedDate,
  };

  console.log(JSON.stringify(payment_body.command));
  return payment_body;
};
