import "./style.scss";
import titleLandingpage from "../../images/title-landing-page.svg";
import btnSearchIcon from "../../images/btn-search-icon.svg";
import { IconButton, Stack, TextField, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import withNavigateHook from "../../components/WithNavigation";
import {
  clearStoreItem,
  logout,
  setUserMode,
  updateUserLocation,
} from "../../store/actions/actions";
import { connect } from "react-redux";
import { Autocomplete } from "@react-google-maps/api";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import NavBar from "../../components/AppLayout/navbar/NavBar";
import Logoappmobile from "../../images/Logo-app-mobile.svg";
import Closebtn from "../../images/close-btn.svg";
import Footer from "../../components/AppLayout/footer/Footer";
import LocationIcon from "../../images/LocationOnRoundedIcon.svg";
import { useGeolocated } from "react-geolocated";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { config } from "../../config";
function HomePage(props: any) {
  const location = useLocation();
  const [isSimple, setIsSimple] = useState<boolean>(false);
  const [isRestaurantView, setIsRestaurantView] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [autoComplete, setAutoComplete] = useState<any>();
  const [addressText, setAddressText] = useState<string>(
    props.location?.shownAddress ? props.location?.shownAddress : ""
  );
  const [hasListener, setHasListener] = useState<boolean>(false);
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
  const [loggedUser, setloggedUser] = useState(false);
  const [isBannerVisible, setBannerVisible] = useState(true);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const navigate = useNavigate();

  const openCart = () => {
    if (props.savedCategories && Object.values(props.savedCategories)[0])
      setCartOpen(true);
  };
  useEffect(() => {
    props.setUserMode(0);
    props.clearItemStore("user");
    props.clearItemStore("authGoogleSuccess");
    props.clearItemStore("authAppleSuccess");
    props.clearItemStore("auth3dthird");
    props.clearItemStore("authError");
  }, []);

  useEffect(() => {
    setKey(config.key_google); // Your API key here.

    if (props.token) {
      setloggedUser(true);
    } else {
      setloggedUser(false);
    }
  }, [props.token]);

  let place: any;

  useGeolocated({
    onSuccess: (success: GeolocationPosition) => {
      let obj = {
        lat: success?.coords?.latitude,
        lng: success?.coords?.longitude,
      };
      if (
        !props?.location ||
        props?.location?.shownAddress == undefined ||
        props?.location?.shownAddress == ""
      )
        fromLatLng(obj.lat, obj.lng)
          .then((res) => {
            if (res.status == "OK") {
              console.log("UPDATED LOC");
              props.updateUserLocation({
                ...obj,
                shownAddress: res?.results[0]?.formatted_address,
              });
            }
          })
          .catch((error) => console.log("error", error));
    },
  });

  useEffect(() => {
    // Logic to determine the navbar state based on the current location
    if (
      location.pathname.includes("/Authentification") ||
      location.pathname.includes("/payment")
    ) {
      setIsSimple(true);
      setIsExpanded(false);
      setIsRestaurantView(false);
    } else {
      if (location.pathname.includes("/restaurant")) {
        setIsSimple(false);
        setIsExpanded(false);
        setIsRestaurantView(true);
      } else {
        setIsSimple(false);
        setIsExpanded(true);
        setIsRestaurantView(false);
      }
    }

    //logic to manage the scroll events for the expanded nav
    if (location.pathname === "/" && !hasListener) {
      // Attach the scroll event listener
      // window.addEventListener("wheel", handleScroll);
      // setHasListener(true);
    } else if (hasListener) {
      // Remove the scroll event listener if it was previously added
      // window.removeEventListener("wheel", handleScroll);
      // setHasListener(false);
    }
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      // window.removeEventListener("wheel", handleScroll);
    };
  }, [location.pathname]);

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    props.clearItemStore("auth3dthird");
    props.logout();
    props.navigate("/");
  };

  const isLargeScreen = useMediaQuery("(min-width: 601px)");

  const onPlaceChanged = () => {
    try {
      if (!autoComplete) return;
      console.log("palce not auto ");
      place = autoComplete?.getPlace();

      let obj = {
        lat: place?.geometry?.location.lat(),
        lng: place?.geometry?.location.lng(),
      };

      props.updateUserLocation({
        ...obj,
        shownAddress: place?.formatted_address,
      });
      if (place?.formatted_address != undefined) {
        if (place?.formatted_address == undefined) {
          console.log("place");

          return;
        }
        if (place?.formatted_address) {
          if (props.location?.pathname !== "/restaurants") {
            console.log("place");
            props.navigate("/restaurants");

            setAddressText(place?.formatted_address);
          }
        }
      }
    } catch (error) {
      console.log("place", { error });
    }
  };

  //Search locatio Autocomplete states

  //Search locatio Autocomplete states
  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };

  //logic for the user dropdown menu (profile , lg out , setting )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runLocation = () => {
    if (props.location?.shownAddress) {
      if (props.location?.pathname !== "/restaurants") {
        props.navigate("/restaurants");

        setAddressText(props.location?.shownAddress);
      }
    }
  };
  useEffect(() => {
    if (props.location?.shownAddress)
      setAddressText(props.location?.shownAddress);
  }, [props.location?.shownAddress]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      props.clearItemStore("location");

      setAddressText("");
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setIsIOS(true);
    }
    if (/android/.test(userAgent)) {
      setIsAndroid(true);
    }
  }, []);

  const closeBanner = () => {
    setBannerVisible(false);
  };
  return (
    <div className={`home-page ${isBannerVisible ? "" : "mobile_version"}`}>
      {isBannerVisible && (
        <div className="mobile-banner">
          {isIOS && (
            <div className="ios-device">
              <div className="banner-wrapper">
                <div className="content-app">
                  <img
                    className="close-btn"
                    src={Closebtn}
                    alt=""
                    onClick={closeBanner}
                  />
                  <div className="info-block">
                    <img src={Logoappmobile} alt="" />
                    <div>
                      <p>FIOEAT pour IOS</p>
                      <span>Disponible sur AppStore</span>
                    </div>
                  </div>
                </div>
                <button>
                  <a href="https://apps.apple.com/tn/app/fioeat/id6449358188">
                    <p>Installer</p>
                  </a>
                </button>
              </div>
            </div>
          )}

          {isAndroid && (
            <div className="android-device">
              <div className="banner-wrapper">
                <div className="content-app">
                  <img
                    className="close-btn"
                    src={Closebtn}
                    alt=""
                    onClick={closeBanner}
                  />
                  <div className="info-block">
                    <img src={Logoappmobile} alt="" />
                    <div>
                      <p>FIOEAT pour Android</p>
                      <span>Disponible sur Play Store</span>
                    </div>
                  </div>
                </div>
                <button>
                  <a href="https://play.google.com/store/apps/details?id=com.fioeat&hl=en_US">
                    <p>Installer</p>
                  </a>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="BodyPage">
        <div className="fixed-header-nav">
          <NavBar />
        </div>
        <div className="landing-page-component-content">
          <div className="search-content">
            {/* <img className="title-block" src={titleLandingpage} alt="" /> */}
            <p className="title-block">
              Vos restaurants préfères <br /> au bout du doigt.
            </p>
            <Stack className="input-section-search" direction="column">
              {isExpanded && !isSimple ? (
                <div className="inputSearch">
                  <Autocomplete
                    restrictions={{ country: "fr" }}
                    onPlaceChanged={() => onPlaceChanged()}
                    onLoad={(autocomplete) => onAutoCompleteLoad(autocomplete)}
                  >
                    <TextField
                      className="search-engine-restaurant"
                      // size="small"
                      placeholder="Cherchez un restaurant à proximité de vous !"
                      value={addressText}
                      onKeyDown={handleKeyDown}
                      onChange={(evt) => setAddressText(evt.target.value)}
                      InputProps={{
                        startAdornment: (
                          <div className="">
                            <img src={LocationIcon} alt="" />
                          </div>
                        ),
                      }}
                    />
                  </Autocomplete>
                  <div className="icon-search">
                    <img src={btnSearchIcon} alt="" onClick={runLocation} />
                  </div>
                  {/* <GoogleMaps /> */}

                  {/* <NewGoogleMaps /> */}
                </div>
              ) : null}
            </Stack>
          </div>
          {/* <SearchLocInput /> */}
        </div>
      </div>
      <div className="fixed-footer">
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    authError: state.auth.authError,
    savedCategories: state.cart.savedCategories,
    token: state.auth.token,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    updateUserLocation: (location: any) =>
      dispatch(updateUserLocation(location)),
    setUserMode: (mode: number) => dispatch(setUserMode(mode)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(HomePage));
