import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withNavigateHook from "../WithNavigation";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import { useState } from "react";
import NavBar from "../Nbar/Nav-bar";
import Footer from "../AppLayout/footer/Footer";
import "./apropos.scss";
import LeftArrow from "../../images/arrow-left_grey.svg";

function Apropos(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [RestorentFilter, setRestorentFilter] = useState("");

  const apropos: boolean = location.pathname === "/apropos-politique";
  const ScrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="Apropos">
      <NavBar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />
      <div className="BodyPage">
        <div className="TopPageHeader">
          <Link to="/">
            <div>
              <img src={LeftArrow} alt="" />
              <p>À propos</p>
            </div>
          </Link>
        </div>
        <Tabs
          className="Tabs"
          aria-label="Vertical tabs"
          orientation="vertical"
        >
          <div className="TabNavigation">
            {!apropos ? (
              <TabList>
                <Tab>
                  <p onClick={ScrollTop}>Conditions générales d'utilisation</p>
                </Tab>
                <Tab>
                  <p onClick={ScrollTop}>Politique de confidentialité</p>
                </Tab>
              </TabList>
            ) : (
              <TabList>
                <Tab>
                  <p onClick={ScrollTop}>Politique de confidentialité</p>
                </Tab>
                <Tab>
                  <p onClick={ScrollTop}>Conditions générales d'utilisation</p>
                </Tab>
              </TabList>
            )}
          </div>
          <div className="TabContent">
            <TabPanel value={apropos ? 1 : 0}>
              <b>1. Conditions</b>
              <p>
                En accédant au site Web à l'adresse fioeat.com, vous acceptez
                d'être lié par les présentes conditions d'utilisation, toutes
                les lois et réglementations applicables, et vous acceptez de
                vous conformer aux lois locales en vigueur. Si vous n'êtes pas
                d'accord avec l'une de ces conditions, il vous est interdit
                d'utiliser ou d'accéder à ce site. Le contenu de ce site Web est
                protégé par le droit d'auteur et le droit des marques en
                vigueur.
              </p>
              <b>2. Licence d’utilisation</b>
              <p>
                La permission est accordée de télécharger temporairement une
                copie du matériel (information ou logiciel) sur le site web de
                Frenchinnov à des fins de visualisation transitoire personnelles
                et non commerciales. Il s’agit de l’octroi d’une licence et non
                d’un transfert de titre. En vertu de cette licence, vous ne
                pouvez pas
              </p>
              <ul>
                <li>Modifier ou copier le matériel ;</li>
                <li>
                  Utiliser le matériel à des fins commerciales ou pour tout
                  affichage public (commercial ou non commercial) ;
                </li>
                <li>
                  Tenter de décompiler ou de désosser tout logiciel figurant sur
                  le site Web de Frenchinnov ;
                </li>
                <li>
                  Supprimer tout copyright ou toute autre mention de propriété
                  du matériel{" "}
                </li>
                <li>
                  Ou transférer les documents à une autre personne ou les
                  "dupliquer" sur un autre serveur.
                </li>
              </ul>
              <b>3. Déni de responsabilité</b>
              <p>
                pLe matériel sur le site Web de Frenchinnov est fourni «tel
                quel». Frenchinnov n'offre aucune garantie, explicite ou
                implicite, et décline par la présente toute autre garantie,
                notamment les garanties ou conditions implicites de qualité
                marchande, d'adéquation à un usage particulier, de non-violation
                de la propriété intellectuelle ou d'une autre violation des
                droits. En outre, Frenchinnov ne garantit ni ne fait aucune
                déclaration concernant l’exactitude, les résultats probables ou
                la fiabilité de l’utilisation des informations sur son site
                Internet ou de toute autre manière liée à ces informations ou à
                tout site lié à ce site.
              </p>
            </TabPanel>
            <TabPanel value={apropos ? 0 : 1}>
              <b>1. Quel informations on collecte?</b>
              <p>
                On collecte les informations personnelles que vous nous
                fournissez volontairement:
              </p>
              <ul>
                <li>Nom</li>
                <li>Prénom</li>
                <li>Email</li>
                <li>Adresse</li>
                <li>Mot de passe.</li>
                <li>Numéro de téléphone.</li>
                <li>Localisation</li>
                <li>Geolocalisation</li>
              </ul>

              <p>
                Les informations que nous recueillons dépend du contexte de
                votre interaction avec nous et nos services. Vous êtes libre de
                refuser notre demande d'informations personnelles, sachant que
                nous pourrions ne pas être enmesure de vous fournir certains des
                services que vous souhaitez.
              </p>
              <b>2. Comment on utilise vos informations?</b>
              <p>
                Nous ne demandons des informations personnelles que lorsque nous
                en avons vraiment besoin pour vous fournir unservice. Nous le
                recueillons par des moyens justes et légaux, avec votre
                connaissance et votre consentement. Nousvous expliquons
                également pourquoi nous le collectons et comment il sera
                utilisé. Nous ne conservons les informations collectées que le
                temps nécessaire pour vous fournir le service demandé.
                Lesdonnées que nous stockons sont protégées par des moyens
                commercialement acceptables afin d’empêcher la perte et levol,
                ainsi que par tout accès, divulgation, copie, utilisation ou
                modification non autorisée. On utilise les informations dont on
                collecte et reçoit pour:
              </p>
              <ul>
                <li>
                  Faciliter la création de votre compte à travers les
                  informations que vous nous autoriser de collecter de Googleet
                  Facebook.
                </li>
                <li>Surveillance et prévention de la fraude.</li>
                <li>Répondre à des demandes avec intérêt juridique.</li>
                <li>Gestion de comptes utilisateurs.</li>
                <li>
                  Vous fournir les services que vous choisissez d'utiliser.
                </li>
                <li>
                  Analyse de données pour évaluer et améliorer nos services.
                </li>
              </ul>

              <b>3. Avec qui on partage vos informations?</b>
              <p>
                Nous ne partageons aucune information d'identification
                personnelle publiquement. On partage vos informations
                d'identification personnelle avec des tiers pour des usages
                différents:
              </p>
              <p>
                Chaines et restaurants clients de Frenvinnov: Identification du
                destinataire d'une commande. Informations paiement relatives à
                une commande, seul l'identifiant de la transaction avec laquel
                unecommande est passé est partagée. Analytics. Stripe:
                Traitement des transactions de paiement. Protection contre la
                fraude. Azure: Fournisseur d'infrastructures matérielles et
                logicielles sur lequels on stocke et traite vos données.
                Facebook: Authentification. Google et Google Maps: Suggestion
                d'adresse. Géolocalisation. Authentification. On partage vos
                informations d'identification personnelle lorsque requis par la
                loi. Notre site Web peut contenir des liens vers des sites
                externes que nous n'exploitons pas. Sachez que nous n'avonsaucun
                contrôle sur le contenu et les pratiques de ces sites et que
                nous ne pouvons accepter aucune responsabilitépour leurs
                politiques de confidentialité respective. Votre utilisation
                continue de notre site Web sera considérée comme une acceptation
                de nos pratiques en matière deconfidentialité et d'informations
                personnelles. Si vous avez des questions sur la manière dont
                nous traitons lesdonnées des utilisateurs et les informations
                personnelles, n’hésitez pas à nous contacter.
              </p>
            </TabPanel>
          </div>
        </Tabs>
      </div>
      <Footer></Footer>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    token: state.auth.token,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Apropos));
