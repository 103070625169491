import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { shopApi } from "../../services/apiGate";
import { getRestaurantMenuSuccess } from "../actions/actions";

export function* getShopMenuSaga(action: any) {
  try {
    let response: AxiosResponse = yield shopApi.getShopMenu({
      id: action.body.id,
      diningMode: action.body.diningMode != null ? action.body.diningMode : 0,
      adresse: action.body.adresse,
    });

    if (response.data) {
      let data: serviceMenuModels.getMenuResponse = response.data;

      yield put(getRestaurantMenuSuccess(data));
    }
  } catch (error: any) {}
}
