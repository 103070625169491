import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isloading: false,
  payementSuccess: null,
  payRetries: 0,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.CREATE_PAYMENT:
      return {
        ...state,
        payementSuccess: null,
      };
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payementSuccess:
          action.order?.status === "succeeded" || action?.order?.QrCode != null
            ? true
            : false,
        payRetries: state.payRetries + 1,
        payementResponse: action.order,
      };
    case actionTypes.CREATE_PAYMENT_FAIL:
      return {
        ...state,
        payementSuccess: false,
      };
    case actionTypes.CLEAR_STORE_ITEM: {
      var key = action?.item;
      var obj: any = {};
      obj[key] = null;

      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}

export default reducer;
