import LogoImg from "../../images/fioteat-logoo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function NavAuth() {
  const navigate = useNavigate();
  return (
    <div className="nav-auth">
      <div className="nav-wrapper">
        <Link to="/" onClick={(e) => e.stopPropagation()}>
          <img
            src={LogoImg}
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
