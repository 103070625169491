import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Grid,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import appleBadge from "../../../images/Mobile-app-store_badge.svg";
import googlePlayBadge from "../../../images/Mobile-play-store_badge.svg";
import whiteLogo from "../../../images/fioeat-logo.png";
import logoOrange from "../../../images/fioEat_white.svg";
import "./style.scss";
// import Link from '@mui/joy/Link';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { languageData } from "../../../i18n";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  const navigate = useNavigate();
  let tab = 0;
  const handleNavigate = () => {
    navigate("/apropos", { state: { idOfTab: tab } });
  };
  const navigateToApple = () => {
    window.open("https://apps.apple.com/tn/app/fioeat/id6449358188", "_blank");
  };
  const navigateToGoogle = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.fioeat&hl=en_US",
      "_blank"
    );
  };
  return (
    <footer id="footerContainer" className="">
      <div className="footer-wrapper">
        <div className="left-side">
          <div className="side-wrapper sideSection column">
            <div className="footer-logo">
              <img className="logo_footer" src={logoOrange} alt="fioeat-logo" />
              <Typography>{languageData.fr.Footer.str_01}</Typography>
            </div>
            <div className="side-wrapper row buttons-box mobile-footer">
              <Button className="row" onClick={navigateToApple}>
                <img src={appleBadge} alt="" className="badgeImg" />
              </Button>
              <Button className="row" onClick={navigateToGoogle}>
                <img src={googlePlayBadge} alt="" className="badgeImg" />
              </Button>
            </div>
            <div className="sub-list-footer">
              <Typography>
                <ul className="footer-menu">
                  <li>
                    <Link to="/apropos">{languageData.fr.Footer.str_02}</Link>
                  </li>
                  <li>
                    <Link to="/apropos-politique">{languageData.fr.Footer.str_03}</Link>
                  </li>
                  <li>{languageData.fr.Footer.str_04}</li>
                  <li>{languageData.fr.Footer.str_05}</li>
                  <li>{languageData.fr.Footer.str_06}</li>
                </ul>
              </Typography>
            </div>
          </div>
        </div>
        <div className="right-side column">
          <div className="side-wrapper row buttons-box desktop-footer">
            <Button style={{padding:"0"}} className="row" onClick={navigateToApple}>
              <img src={appleBadge} alt="" className="badgeImg" />
            </Button>
            <Button style={{padding:"0"}} className="row" onClick={navigateToGoogle}>
              <img src={googlePlayBadge} alt="" className="badgeImg" />
            </Button>
          </div>
          <Typography className="copyright">© 2024 FRENCHINNOV.</Typography>
        </div>
      </div>
    </footer>
  );
}
