import ClippedDrawer from "../../components/Cart";

export default function TestPage() {
    return <div className="main " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* <CardUno   hasComment={false} /> */}
        {/* <CardDos  /> */}
        {/* <SideBar /> */}
        {/* <CategoryWheel />
        <GoogleMaps/> */}
        {/* <CardTres  /> */}
        
        <ClippedDrawer />

    </div>
}