import React from "react";
import {
  BrowserRouter,
  MemoryRouter,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import AuthLayout from "../components/AppLayout";
import HomePage from "../../src/apps/home/index";
import Authentification from "../apps/authentification";
import OtpCode from "../apps/authentification/OtpPage";
import PersonalInfo from "../apps/authentification/PersonalInfoPage";
import TermsAndConditionsPage from "../apps/authentification/TermsAndConditionsPage";
import TestPage from "../apps/Test/TestPage";
import SuccessPage from "../apps/authentification/SuccessPage";
import Restaurant from "../apps/restaurant";
import MenuView from "../apps/menu";
import OrderView from "../apps/order/index";
import Payment from "../apps/Payment";
import PasswordPage from "../apps/authentification/PasswordPage";
import Apropos from "../components/apropos/Apropos";
import MonProfile from "../components/monProfile/MonProfile";
import ForgetPassword from "../apps/authentification/ForgetPassword";
import OtpPageForgetPassword from "../apps/authentification/OtpPageForgetPassword";
import ChangePassword from "../apps/authentification/ChangePassword";
import Paiement from "../apps/paiement";
import MesCommandes from "../apps/mes-commandes/MesCommandes";

function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/Test/testComponents" element={<TestPage />} />
        <Route path="/restaurants" element={<Restaurant />} />
        <Route path="/restaurant/:id" element={<MenuView />} />
        
        {/* <Route
          path="/restaurant/:id/category/:categoryId"
          element={<OrderView />}
        /> */}

        <Route
          path="/restaurant/:id/menu/:menuId/category/:categoryId"
          element={<OrderView />}
        />
        
        <Route path="/" element={<HomePage />} />

        <Route path="/Authentification" element={<Authentification />} />

        <Route path="/Authentification/otp" element={<OtpCode />} />
        <Route
          path="/Authentification/personal-info"
          element={<PersonalInfo />}
        />
        <Route
          path="/Authentification/passwordPage"
          element={<PasswordPage />}
        />
        <Route
          path="/Authentification/forget-password"
          element={<ForgetPassword />}
        />
        <Route
          path="/Authentification/change-password"
          element={<ChangePassword />}
        />
        <Route
          path="/Authentification/otp-forget-password"
          element={<OtpPageForgetPassword />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/success" element={<SuccessPage />} />

        <Route path="/apropos" element={<Apropos />} />
        <Route path="/apropos-politique" element={<Apropos />} />
        <Route path="/mon-profile" element={<MonProfile />} />

        <Route path="/paiement" element={<Paiement />} />
        <Route path="/mes-commandes" element={<MesCommandes />} />
      </Routes>
    </HashRouter>
  );
}

export default AppRoutes;
