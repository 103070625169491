import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/Nbar/Nav-bar";
import LeftArrow from "../../images/arrow-left_grey.svg";
import SearchIcon from "../../images/SearchIconGrey.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import CharIcon from "../../images/char.svg";
import EmptyList from "../../images/empty-cartIcon.svg";
import "./mes-commandes.scss";
// import InvoiceCommand from "../paiement/ProcessingCommand/InvoiceCommand";
import CommandElement from "./CommandElement";
// import CommandDetailsInfo from "./CommandDetails/CommandDetails";
import CartHeader from "../../components/Nbar/Cart/CartHeader";
import CartElements from "../../components/Nbar/Cart/CartElements";
import { orderApi } from "../../services/apiGate";
import { Commande, orderResponse } from "../../models/services/module.order";
import CartHeaderDetails from "./CommandDetails/CartHeader";
import CartElementsDetails from "./CommandDetails/CartElement";
import { Link } from "react-router-dom";
import SkeletonElement from "../skeleton/SkeletonElement";
import QRcodeTest from "../../images/QRcodeTest.svg";

export default function MesCommandes() {
  const [loading, setloading] = useState(false);

  const [RestorentFilter, setRestorentFilter] = useState("");
  const [selectedOrder, setselectedOrder] = useState<Commande>();
  const [orders, setorders] = useState<Commande[]>([]);
  const [tempOrders, settempOrders] = useState<Commande[]>([]);
  const [handleCodeQr, sethandleCodeQr] = useState(false);
  const [qrcode, setqrcode] = useState("");
  const [orderDetails, setorderDetails] =
    useState<serviceDetailsOrderModels.detailsOrder>();

  const fetchOrders = async () => {
    try {
      setloading(true);
      let response = await orderApi.getOrdersList();
      let orderList: orderResponse = response.data;
      if (orderList) {
        let orderListData = orderList.commandes?.reverse();
        setorders(orderListData);
        settempOrders(orderListData);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const fetchOrderDetails = async (id: string) => {
    try {
      let response = await orderApi.getOrderById(id);
      let orderDetails = response.data;
      if (orderDetails) {
        setorderDetails(orderDetails?.commande);
      }
    } catch (error) {}
  };
  const handleCodeQrEl = () => {
    sethandleCodeQr((prevState) => !prevState);
  };
  useEffect(() => {
    if (selectedOrder) {
      fetchOrderDetails("" + selectedOrder.id);
    }
  }, [selectedOrder]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const filterByName = useCallback(
    (query: string) => {
      if (!query.trim()) {
        setorders(tempOrders);
        return;
      }

      const filteredOrders = tempOrders.filter((order) =>
        order.nomRestaurant.toLowerCase().includes(query.toLowerCase())
      );
      setorders(filteredOrders);
    },
    [orders]
  );
  const CloseCommandDetails = () => {
    document
      .querySelector(".commands-wrapper")
      ?.classList.remove("ShowCommandDetails");
    document.querySelector(".QrCode img")?.classList.remove("ShowQr");
  };
  const commandElementsArray = new Array(7).fill(null);
  const convertHexToByteArray = (hexString: string) => {
    const byteCharacters = atob(hexString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  };
  useEffect(() => {
    if (orderDetails) {
  
      let byteArray = convertHexToByteArray(orderDetails.qrCode);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      const imageUrl = URL.createObjectURL(blob);
      setqrcode(imageUrl);
    }
  }, [orderDetails]);

  return (
    <div className="MesCommandes">
      <NavBar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />
      <div className="TopPageHeader">
        <div>
          <Link to="/">
            <img src={LeftArrow} alt="" />
          </Link>
          <p>Mes commandes</p>
        </div>
      </div>
      <div className="Commandes-list">
        <div className="commands-wrapper">
          <div onClick={CloseCommandDetails} className="overlayMobile"></div>
          <div className="CommandsListSection">
            <div className="CommandsHead">
              <div className="ListFilter">
                <div className="filter-field">
                  <img src={SearchIcon} alt="" />
                  <input
                    onChange={(e) => filterByName(e.target.value)}
                    type="text"
                    placeholder="Recherche..."
                  />
                </div>
              </div>
              <div className="CommandsPassed">
                <p>{orders?.length} Commande(s) passée (s)</p>
              </div>
            </div>

            {orders?.length < 1 && !loading ? (
              <div className="EmptyList">
                <img src={EmptyList} alt="" />
                <p>Vous avez aucune commandes </p>
                <span>
                  Dès que vous ajoutez des commandes ils apparaîtront ici dans
                  votre profile
                </span>
              </div>
            ) : (
              ""
            )}

            <div className="Allcommands">
              {loading && (
                <div>
                  {commandElementsArray.map((_, index) => (
                    <div
                      key={index}
                      className="CommandElement"
                      style={{
                        marginBottom: 10,
                        height: 80,
                      }}
                    >
                      <SkeletonElement type="img-block" />
                    </div>
                  ))}
                </div>
              )}

              {orders?.map((el, i) => (
                <CommandElement
                  selectOrder={setselectedOrder}
                  key={i}
                  order={el}
                />
              ))}
            </div>
          </div>
          <div className="SelectedCommandDetails">
            <img
              onClick={CloseCommandDetails}
              src={CloseIcon}
              alt=""
              className="closeBtn"
            />
            <CartHeaderDetails order={selectedOrder} />

            {/* <img  className="QrCode" src={QRcodeTest} alt="" width={"80px"} /> */}

            {orderDetails?.status === "cashPayment" ? (
              <div className="QrCode">
                <img
                  onClick={handleCodeQrEl}
                  className={handleCodeQr ? "ShowQr" : ""}
                  src={qrcode}
                  alt=""
                  width={"80px"}
                />
              </div>
            ) : null}

            <CartElementsDetails
              orderDetails={orderDetails}
              selectedOrder={selectedOrder}
            />
            {/* <div className="recommend">
              <img src={CharIcon} alt="" />
              <p className="txt">Recommander</p>
              <p className="total-price">{selectedOrder?.totalPrix}€</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
