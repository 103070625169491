import { Button, FormControl } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import withNavigateHook from "../../components/WithNavigation";
import "./style.scss";
import { signup, signupSuccess } from "../../store/actions/actions";
import { connect } from "react-redux";
import { NavAuth } from "./nav";

const TermsAndConditionsPage = (props: any) => {
  const [accpetBox, setAccpetBox] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let user: serviceAuthModels.signupBody = props.user;

    if (!accpetBox) {
      setError(true);
    } else {
      props.signup({
        ...user,
        phoneNumber: props.user.phone,
        validationSignupToken: props.validationSignupToken,
      });
    }
  };

  const redirectedToAuth = Boolean(localStorage.getItem("redirectedToAuth"));

  window.addEventListener("popstate", (event) => {
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      //setshowPage(true)
      props.navigate("/Authentification");
    }
  }, []);

  const handleGoBack = () => {
    props.navigate("/");
  };

  useEffect(() => {
    if (props.userSignUpSuccess) {
      if (props.userSignUpSuccess) {
        if (
          props.location?.shownAddress &&
          props.location?.shownAddress !== ""
        ) {
          props.navigate("/restaurants");
        } else {
          props.navigate("/");
        }
        if (redirectedToAuth !== false && redirectedToAuth !== null) {
          props.navigate("/paiement");
        }
      } else alert("Error");
    }
  }, [props.userSignUpSuccess]);

  return (
    <div className="authPage">
      <NavAuth />
      <div style={{ maxWidth: "480px" }} className="content conditions-auth">
        <p className="tite-form">
          Conditions générales d'utilisation et Politique de confidentialité
        </p>
        <div className="description-Conditions">
          <p>
            Veuillez accepter les conditions générales et la politique de
            confidentialité
          </p>
          <p>
            En cochant sur “<b>J’accepte</b>”, ci-dessous, j’accepte les{" "}
            <b>conditions générales</b>, et la{" "}
            <b>politique de confidentialité</b>.
          </p>
        </div>

        <FormControl className="form-control">
          <p>J'accepte</p>
          {/* <Checkbox
            color="info"
            checked={accpetBox}
            onChange={(e) => setAccpetBox(!accpetBox)}
            inputProps={{ "aria-label": "controlled" }}
          ></Checkbox> */}
          <input
            className="styled-checkbox"
            checked={accpetBox}
            onChange={(e) => setAccpetBox(!accpetBox)}
            type="checkbox"
            name=""
            id=""
          />
        </FormControl>
        {error && (
          <p style={{ color: "red" }}>
            Vous devez accepter les termes et conditions.
          </p>
        )}
        <div id="navButtonGroup">
          <button id="back" onClick={handleGoBack}>
            {" "}
            <NavigateBeforeIcon />{" "}
          </button>
          <button onClick={(e) => handleSubmit(e)} id="next">
            {" "}
            {"Suivant"} <NavigateNextIcon />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    userSignUpSuccess: state.auth.userSignUpSuccess,
    validationSignupToken: state.auth.validationSignupToken,
    location: state.auth.location,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signup: (body: serviceAuthModels.signupBody) => dispatch(signup(body)),
    signupSuccess: (res: any) => {},
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(TermsAndConditionsPage));
