import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  Box,
  Grid,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { shopApi } from "../../services/apiGate";
import { config } from "../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClippedDrawer from "../../components/Cart/index";
import NavBar from "../../components/Nbar/Nav-bar";
import {
  AspectRatio,
  Button,
  Card,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/joy";
import { IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";

import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import CardTres from "../../components/Cards/CardTres";
import withNavigateHook from "../../components/WithNavigation";
import {
  addShopToFavorite,
  categoryShopsChangeShopMode,
  clearStoreItem,
  getRestaurantMenu,
  logout,
  selectCategory,
} from "../../store/actions/actions";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

import FioEat from "../../images/fioteat-logoo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import restaurant from "../restaurant";
import Preloader from "./../../components/Preloader/Preloader";
import { findCurrentShift } from "../../utils/shopSubtittleFormat";
const api_baseUrl_Picture = config.api_baseUrl_Picture;

const style = {
  position: "absolute",
  top: "65px",
  right: "12%",
  width: 400,
  height: 191,
  borderRadius: "12px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MenuRender = (props: any) => {
  
  let obj: any = props.obj;
  if (obj.categories && obj.categories.length > 0) {
    let isShortCard = false;

    obj.categories.map((val: any) => {
  
      if (val.description) {
      }
    });

    return (
      <div className="listing-menu-section">
        {obj?.categories?.map((val: any, i: any) => {
          // Check if the category is out of stock
          
          return (
            <div className="listing-menu-section-element" key={i}>
              <CardTres
                imageBaseUrl={props.imageBaseUrl}
                location={props.location}
                isOutOfStock={val.isOutOfStock}
                cardProps={val}
                isFinalProduct={isShortCard}
                menuId={props.menuId}
                menuid={props.menuId}
                objId={props.obj}

              />
            </div>
          );
        })}
      </div>
    );
  }
  //  else if (obj.categories && obj.categories.length === 1) {
  //   return (
  //     <div className="listing-menu-section">
  //       {obj.categories.map((val: any, i: any) => {
  //         let isFinalProduct = val.description ? false : true;
  //         return (
  //           <div className="listing-menu-section-element">
  //             <CardTres
  //               cardProps={val}
  //               isFinalProduct={isFinalProduct}
  //               menuId={i}
  //             />
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // } else if (obj.components && obj.components.length > 1) {
  //   return (
  //     <div className="listing-menu-section">
  //       {obj.components.map((val: any, i: any) => {
  //         let isFinalProduct = val.description ? false : true;
  //         return (
  //           <div className="listing-menu-section-element">
  //             <CardTres
  //               cardProps={val}
  //               isFinalProduct={isFinalProduct}
  //               menuId={i}
  //             />
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  // if (obj.categories && obj.categories.length === 1) {
  //   return <MenuRender obj={obj.categories[0]} />;
  // }
  // if (obj.products && obj.products.length === 1) {
  //   return <MenuRender obj={obj.products[0]} />;
  // }
  // if (obj.components && obj.components.length === 1) {
  //   return <MenuRender obj={obj.components[0]} />;
  // }

  return <div>no menu</div>;
};

type sectionMenu = {
  title: string;
  data: serviceMenuModels.Category[];
};

function smoothScrollByAmountWithDelay(
  amount: number,
  duration: number,
  delay: number
) {
  setTimeout(() => {
    const startTimestamp = performance.now();
    const startY = window.scrollY;

    function scrollAnimation(timestamp: number) {
      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      const currentY = startY + amount * progress;

      window.scrollBy(0, currentY - window.scrollY);

      if (progress < 1) {
        requestAnimationFrame(scrollAnimation);
      }
    }

    requestAnimationFrame(scrollAnimation);
  }, delay);
}

function MenuView(props: any) {
  const [activeRestaurant, setactiveRestaurant] = useState<number>();
  const [TopMenuListSection, setTopMenuListSection] = useState<sectionMenu[]>();
  const [loggedUser, setloggedUser] = useState(false);
  const [restFav, setRestFav] = useState(props.activeRestaurant?.isFavorite);
  const [shopsFio, SetShopsFio] = useState<any[]>(props?.menu?.menus);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [value, setValue] = useState<number | null>(null);
  const [curentState, setCurentState] = useState();
  const [openUser, setOpenUser] = useState(false);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [diningMode, setDiningMode] = useState(props.categoryShopMode);
  const [resShifts, setResShifts] = useState<any>();
  const [resData, setResData] = useState<any>();
  const [loadingState, setLoadingState] = useState<any>(false);
  const [RestorentFilter, setRestorentFilter] = useState("");

  const navigate = useNavigate();
  const [changeColor, setChangeColor] = useState(true);
  const [changeColor2, setChangeColor2] = useState(false);
  const [restName, setRestName] = useState<string>();
  const [theData, setTheData] = useState<any>();
  const stateChanged = localStorage.getItem("stateChanged");
  const stateChangedT = stateChanged === "true";

 

  let location = useLocation();

  const imageBaseUrl = config.api_baseUrl_Picture;

  const cartListMaper = Object.values(props.savedCategories);

  const newCartList: any = cartListMaper?.filter(
    (cart: any) => cart?.restaurant?.id == props?.URLParams?.id
  );

  window.addEventListener("popstate", (event) => {
  
    props.clearItemStore("menu");
  });

  useEffect(() => {
    const handleClick = (event:any) => {
      localStorage.setItem("redirectedToPrevPage", "false");
      
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (props?.menu) {
      SetShopsFio(props?.menu?.menus);
    }
  }, [props?.menu?.menus]);
  useEffect(() => {
    
  }, [props.savedCategories]);

  useEffect(() => {}, [shopsFio]);

  let id = props.URLParams.id;

  useEffect(() => {
    setactiveRestaurant(id);
  }, []);

  useEffect(() => {
    if (props.token) {
      setloggedUser(true);
    } else {
      setloggedUser(false);
    }
  }, [props.token]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingState(true);
      try {
        const res = await shopApi.getResturantById({
          id: id,
          diningMode: props.mode,
          adresse: {
            latitude: props.location?.lat,
            longitude: props.location?.lng,
          },
        });
       
        setTheData(res);
        setRestName(res.data?.restaurant?.nom);
        setResShifts(res?.data?.restaurant?.shifts);
        setResData(res?.data?.restaurant);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, props.mode, props.location]);

  let currentShift = resShifts?.filter(
    (shift: any) => shift.isCurrentShift == true
  )?.[0];

 
  // Render the extracted properties
  //console.log("fixiiing", resData?.isOpen ? `${openTime} • ${closeTime}` : "");
  //console.log("fixiiing", moment(resData?.shifts[0]?.open , "HH:mm").format("HH:mm"));

  // useEffect(() => {
  //   setTopMenuListSection([]);

  //   props.getMenu({
  //     id: id,
  //     diningMode: diningMode,
  //     adresse: {
  //       latitude: props.location?.lat,
  //       longitude: props.location?.lng,
  //     },
  //   });
  // }, [props.mode, diningMode]);

  useEffect(() => {
    setTopMenuListSection([]);

    props.getMenu({
      id: id,
      diningMode: diningMode,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    });



  }, [id, diningMode]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Call the API method
  //       const response = await shopApi.getShopMenu({
  //         id: id,
  //         diningMode: diningMode,
  //         adresse: {
  //           latitude: props.location?.lat,
  //           longitude: props.location?.lng,
  //         },
  //       });
  
  //       // Log the response
  //       console.log("Response from shopApi.getShopMenu:", response);
  
  //       // Access the data from the response and do something with it
  //       // For example, if the response has a data property, you can access it like response.data
  //     } catch (error) {
  //       // Handle errors here
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  
  //   // Call the fetchData function
  //   fetchData();
  
  // }, [id, diningMode, props.location]); // Add pr

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    console.log("NewValue" + setValue(newValue));
  };
  const handleChangee = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
    console.log("NewValue" + setActiveTabIndex(newValue));
  };


  const scrollToSection = useCallback((index: number) => {
    const sectionId = `menuSection${index}`;
    const sectionClass = document.querySelectorAll(".tabmenu");
    const sectionElement = document.getElementById(sectionId);
    const sectionElementClassname = document.getElementsByClassName(sectionId);

    if (sectionElement) {
      sectionClass.forEach(function (tab) {
        tab.classList.remove("SelectedTab");
      });

      for (let i = 0; i < sectionElementClassname.length; i++) {
        const element = sectionElementClassname[i] as HTMLElement;
        element.classList.add("SelectedTab");
      }

      window.scrollTo(0, sectionElement.offsetTop - 180);

      // Update the activeTabIndex
      setActiveTabIndex(index);
    }
  },[]);

  // => function is not working correctly (To be fixed soon)
  const menuSections = document.querySelectorAll(".menuSectionContainer");
  const tabmenus = document.querySelectorAll(".tabmenu");

  function handleScroll() {
    menuSections.forEach(function (section, index) {
      const rect = section.getBoundingClientRect();
      if (rect.top<= window.innerHeight && rect.bottom > 650) {
        tabmenus.forEach(function (tabEl) {
          tabEl.classList.remove("SelectedTab");
        });
        const tabmenuElement = document.querySelector(`.menuSection${index}`);
        if (tabmenuElement) {
          tabmenuElement.classList.add("SelectedTab");
        }
       
       
      }
    });
  }
  window.addEventListener("scroll", handleScroll);

  const openCart = () => {
    if (props.savedCategories && Object.values(props.savedCategories)[0])
      setCartOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the active section based on scroll position
      // Replace this logic with your own calculation method
      // Example: Check if the section is in the viewport
      const activeIndex = props.menu?.menus?.findIndex(
        (val: any, i: number) => {
          const sectionId = `menuSection${i}`;
          const sectionElement = document.getElementById(sectionId);

          if (sectionElement) {
            const rect = sectionElement.getBoundingClientRect();
            return rect.top <= 85 && rect.bottom >= 0;
          }

          return false;
        }
      );

      if (activeIndex !== -1) {
        setActiveTabIndex(activeIndex);
      }
    };

    const tabsContainer = document.querySelector(".MuiTabs-scrollableX");

    if (tabsContainer) {
      // Update scroll position when Tabs container is scrolled
      tabsContainer.addEventListener("scroll", () => {
        setScrollPosition(tabsContainer.scrollLeft);
      });
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (tabsContainer) {
        tabsContainer.removeEventListener("scroll", () => {});
      }
    };
  }, [props.menu]);

  useEffect(() => {
    const tabsContainer = document.querySelector(".MuiTabs-scrollableX");
    // Scroll to the active tab when it changes
    if (tabsContainer) {
      tabsContainer.scrollTo({
        left: activeTabIndex * 100, // Adjust the value as needed
        behavior: "smooth",
      });
    }
  }, [activeTabIndex]);

  const shopDataString = localStorage.getItem("shop");

  const datashop = shopDataString ? JSON.parse(shopDataString) : null;





  const currentShifts = findCurrentShift(datashop);
  useEffect(() => {
    if (diningMode == 0 || diningMode == 1) {
      props.changeCategoryShopMode(diningMode);
    }
  }, [diningMode]);
  return (
    <div className="restaurant-details">
      <NavBar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />

      <Grid className="restaurant-grid">
        {/* <Grid
          item
          xs={12}
          sm={1.8}
          className="menuSideNavContainer"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Stack
            className="menuSideNav"
            padding={"0 15px 0 40px"}
            sx={{ position: "sticky", top: "100px" }}
          >
            {!props.menuLoading ? (
              <>
                {shopsFio?.map((val: any, i: number) => {
                  return (
                    <Typography
                      className={`tabmenu menuSection${i}`}
                      sx={{
                        color: "white",
                      }}
                      // sx={{
                      //   color: `${i === activeTabIndex ? "orange" : "white"}`,
                      //   textDecoration: `${i === activeTabIndex ? "underline" : ""}`,
                      //   cursor: "pointer",
                      // }}
                      onClick={() => scrollToSection(i)} // Scroll to the section
                    >
                      {val.name}
                    </Typography>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </Stack>
        </Grid> */}

        <Grid className="listing-menus">
          <div className="restaurant-header">
            <div className="navigation">
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.clearItemStore("menu");
                  navigate("/restaurants");
                }}
              />

              <div>
                <div className="restaurant-details">
                  <p className="restaurant-name">{restName}</p>
                  <p className="restaurant-time">
                    {loadingState ? (
                      <p>Loading...</p>
                    ) : (
                      <p>
                        {/* {currentShifts?.isCurrentShift ? "Fermé" : "Ouvre"} à{" "}
                         {currentShifts?.open ? moment(currentShifts?.open, "HH:mm").format("HH:mm") : moment(currentShifts.open , "HH:mm").format("HH:mm")} {" • "} 
                        {resData?.distance}Km • {resData?.tempsPrep} min{" "}
                        {resData?.specialites
                          ? "•" + resData?.specialites
                          : null} */}
                        {currentShifts?.isCurrentShift ? "Fermé" : "Ouvre"} à{" "}
                        {currentShifts?.isCurrentShift
                          ? moment(currentShifts?.close, "HH:mm").format(
                              "HH:mm"
                            )
                          : moment(currentShifts?.open, "HH:mm").format(
                              "HH:mm"
                            )}{" "}
                        {resData?.distance}Km • {resData?.tempsPrep} min{" "}
                        {resData?.specialites
                          ? "•" + resData?.specialites
                          : null}
                      </p>
                    )}
                  </p>
                  {/* <hr
                  color="#EEEEEE"
                  style={{
                    height: "1px",
                    marginLeft: "-60px",
                    boxShadow: "0px 1px 1px 1px rgba(179,179,179,0.19)",
                  }}
                /> */}
                </div>
              </div>
            </div>
            {/* <div className="seperator"></div> */}
          </div>

          <Box
            sx={{
              backgroundColor: "white",
              position: "sticky",
              zIndex: 2,
              top: "128px",
              "@media (max-width: 900px)": {
                top: "80",
                marginTop: "20px",
              },
            }}
          >
            <Tabs
              className="tabContainer row MuiTabs-scrollableX tabContainer-menu-items"
              value={activeTabIndex}
              onChange={handleChangee}
              variant="scrollable"
              allowScrollButtonsMobile
              selectionFollowsFocus
              scrollButtons="auto"
              centered
              sx={{
                // display: {
                //   md: "none",
                // },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#EE8113",
                },
                "& .MuiTabs-scrollButtons": {
                  width: "12px",
                },
              }}
            >
              {shopsFio?.map((val: any, i: number) => {
                return (
                  <Tab
                    key={i}
                    value={i}
                    onClick={() => scrollToSection(i)}
                    label={val.name.toLowerCase()}
                    sx={{
                      color: `${i == activeTabIndex ? "#EE8113" : "black"}`,
                      "&.Mui-selected": {
                        color: "#EE8113",
                      },
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          <section className="filter-cat-by-place tabs-menu-section">
            <div className="wrapper-tab">
              {!props.menuLoading && theData?.data?.restaurant?.aEmporte ? (
                <p
                  className={diningMode ? "" : "active-tab"}
                  onClick={() => setDiningMode(0)}
                >
                  {" "}
                  À emporter{" "}
                </p>
              ) : (
                props.menuLoading && (
                  <p
                    className={diningMode ? "" : "active-tab"}
                    onClick={() => setDiningMode(0)}
                  >
                    {" "}
                    À emporter{" "}
                  </p>
                )
              )}

              {!props.menuLoading && theData?.data?.restaurant?.surPlace ? (
                <p
                  className={diningMode ? "active-tab" : ""}
                  onClick={() => setDiningMode(1)}
                >
                  {" "}
                  Sur place{" "}
                </p>
              ) : (
                props.menuLoading && (
                  <p
                    className={diningMode ? "active-tab" : ""}
                    onClick={() => setDiningMode(1)}
                  >
                    {" "}
                    Sur place{" "}
                  </p>
                )
              )}
            </div>
          </section>
          {/* <Tabs
              className="tabContainer row"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              selectionFollowsFocus
              centered
              sx={{
                display: {
                  md: "none",
                },
                textAlign: "center",
                position: "sticky",
                top: 0,
                zIndex: 100,
                backgroundColor: "white",
                marginLeft: "-2px",
                width: "360px",
                paddingLeft: "0",
                paddingRight: "0",
                textTransform: "lowercase",
                "& .MuiTabs-scrollButtons": {
                  width: "25px",
                  color: "white",
                  backgroundColor: "#EE8113",
                  height: "25px",
                  borderRadius: "120%",
                  marginTop: "15px",
                },
              }}
            >
              {props.menu?.menus?.map((val: any, i: number) => {
                return (
                  <Tab
                    key={i}
                    value={val.id}
                    onClick={() => scrollToSection(i)}
                    label={val.name.toLowerCase()}
                    sx={{
                      textTransform: "capitalize",
                      color: "#1D2939",
                      fontSize: "13px",
                      fontweight: 700,
                      textAlign: "center",
                      padding: "0",
                      width: "5px",
                      height: "5px",
                      minWidth: { xs: "90px", md: "90px" },
                    }}
                  />
                );
              })}
            </Tabs> */}

          {/* <IconButton>
                        <ChevronRightIcon />
                    </IconButton> */}
          {!props.menuLoading ? (
            <Stack
              className="menuGrid"
              spacing={4}
              direction="column"
              sx={{ marginTop: "2rem" }}
            >
              <ClippedDrawer
                open={cartOpen}
                setOpen={(bool: boolean) => setCartOpen(bool)}
                newCartList={newCartList}
              />
              {shopsFio
                ?.filter((shop: any) =>
                  shop?.name
                    ?.toLowerCase()
                    .includes(RestorentFilter.toLowerCase())
                )
                .map((val: any, i: number) => {
                  {
                    console.log("object val", val);
                  }
                  return (
                    <Stack
                      className="menuSection full-width menuSectionContainer"
                      key={i}
                      id={"menuSection" + i}
                      spacing={2}
                    >
                      <div className="name-cat">
                        <p>{val.name}</p>
                      </div>
                      <MenuRender
                        obj={val}
                        menuId={i}
                        imageBaseUrl={imageBaseUrl}
                        location={location.state}
                      />
                      {/* {val.id} */}
                    </Stack>
                  );
                })}
            </Stack>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurant: state.menu.activeRestaurant,
    menu: state.menu.menu,
    menue: state.menu,
    menuLoading: state.menu.menuLoading,
    menuAvailableMode: state.menu.menuAvailableMode,
    mode: state.menu.mode,
    menuId: state.menu.menuId,
    savedCart: state.cart.savedCart,
    preSavedCart: state.cart.preSavedCart,
    activeRestaurantId: state.menu.activeRestaurantId,
    savedCategories: state.cart.savedCategories,
    token: state.auth.token,
    userInfo: state.auth.userInfo,
    categoryShopMode: state.category.categoryShopMode,

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      category: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(category, menuId, restaurantId)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    getMenu: (body: serviceMenuModels.getMenuBody) =>
      dispatch(getRestaurantMenu(body)),
    addRestaurantToFavorite: (idRestaurant: number, value: boolean) =>
      dispatch(addShopToFavorite(idRestaurant, value)),
    logout: () => dispatch(logout()),
    changeCategoryShopMode: (value: number) =>
      dispatch(categoryShopsChangeShopMode(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(MenuView));
