import { applyMiddleware, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import authReducer from "./reducers/authReducer";
import viewReducer from "./reducers/viewReducer";
import shopReducer from "./reducers/shopReducer";
import categoryReducer from "./reducers/categoryReducer";
import menuReducer from "./reducers/menuReducer";
import cartReducer from "./reducers/cartReducer";
import orderReducer from "./reducers/orderReducer";
import paymentReducer from "./reducers/paymentReducer";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import rootSaga from "./sagas/saga";

const bindMiddleware = (middleware) => {
  const { composeWithDevTools } = require("redux-devtools-extension");
  //return composeWithDevTools(applyMiddleware(...middleware))
  return applyMiddleware(...middleware);
};

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["location", "userInfo", "token"],
};

const persistConfigCart = {
  key: "cart",
  storage: storage,
  whitelist: ["savedCart", "savedCategories"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);
const persistedReducerCart = persistReducer(persistConfigCart, cartReducer);

const rootReducer = combineReducers({
  auth: persistedReducer,
  view: viewReducer,
  shop: shopReducer,
  category: categoryReducer,
  menu: menuReducer,
  cart: persistedReducerCart,
  order: orderReducer,
  payment: paymentReducer,
});

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware({});

  const store = createStore(
    rootReducer,
    initialState,

    bindMiddleware([sagaMiddleware]),
    { trace: true }
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
