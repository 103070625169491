export const validation = {
  ValidateEmail: (mail: string) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)) {
      return true;
    }

    return false;
  },

  ValidatePhonenumber(inputtxt: string) {
    let phoneno = /^\d+$/;

    if (inputtxt.match(phoneno)) {
      return true;
    }else {
      return false;
    }
  },
 
  ValidateName: (name: string | undefined) => {
    if (/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/.test(name!)) {
      return true;
    }
  
    return false;
  },
}