import storage from "redux-persist/lib/storage";
import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { authApi } from "../../services/apiGate";
import {
  authError,
  editmyprofileFailure,
  editmyprofileSuccess,
  getmyprofileSuccess,
  resendVerificationCodeSuccess,
  sendVerificationCodeSuccess,
  setUserMode,
  signupSuccess,
  update3dthirdPart,
  updateLoading,
  verifyAppleSuccess,
  verifyCodeSuccess,
  verifyGoogleSuccess,
} from "../actions/actions";

export function* startSendVerificationCodeSaga(
  action: serviceAuthModels.SendVerificationCodeAction
) {
  
  try {
    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.sendVerificationCode(
      action.body
    );
    

    if (response.data) {
      yield put(updateLoading(false));

      let data: serviceAuthModels.SendVerificationCodeResponse = response.data;

      yield put(setUserMode(data.mode));
      if (response.data?.token)
        storage.setItem("accessToken", response.data?.token);
      if (!action.resend) yield put(sendVerificationCodeSuccess(data));
      else yield put(resendVerificationCodeSuccess(data));
    }
  } catch (error: any) {
    yield put(updateLoading(false));
    yield put(authError(error.response?.data?.message));
  }
}

export function* startVerifyCodeSaga(
  action: serviceAuthModels.verifyCodeAction
) {
  try {
    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.verifyCode(action.body);

    if (response.data) {
      if (response.data?.token)
        storage.setItem("accessToken", response.data?.token);

      yield put(updateLoading(false));

      let data: serviceAuthModels.verifyCodeResponse = response.data;

      yield put(verifyCodeSuccess(data));
    }
  } catch (error: any) {
    console.log("errorxx startVerifyCodeSaga",error)
    yield put(updateLoading(false));
    yield put(authError(error.response.data?.message));
  }
}

export function* startSignUpSaga(action: serviceAuthModels.signupAction) {
  try {
    let user = {
      email: action.body.email,
      phoneNumber: action.body.phoneNumber,
      firstName: action.body.firstName,
      lastName: action.body.lastName,
      validationSignupToken: action.body.validationSignupToken,
      password: action.body.password,
    };

    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.signup(user);

    if (response.data) {
      storage.setItem("accessToken", response.data?.token);

      yield put(updateLoading(false));

      let data: serviceAuthModels.signupResponse = response.data;

      yield put(signupSuccess(data));
      getMyProfileSaga();
    }
  } catch (error: any) {
    yield put(updateLoading(false));
    yield put(authError(error.response.data?.message));
  }
}

export function* getMyProfileSaga() {
  try {
    let response: AxiosResponse = yield authApi.getmyprofile();
    console.log("errorPR", response);
    if (response?.data) {
      let user: serviceAuthModels.user = response.data?.utilisateur;

      yield put(getmyprofileSuccess(user));
    }
  } catch (error: any) {
    console.log("errorPR", error);
  }
}

export function* editMyProfileSaga(action: any) {
  try {
    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.editmyprofile(action.user);
    console.log("whereerror",response)
    if (response.data) {
      yield put(updateLoading(false));

      let user: serviceAuthModels.user = response.data?.user;

      yield put(editmyprofileSuccess(user));

      getMyProfileSaga();
    }
  } catch (error: any) {
    console.log("whereerror",error)
    yield put(updateLoading(false));
    yield put(editmyprofileFailure(error.response.data?.result));
  }
}

export function* verifyGoogleTokenSaga(action: any) {
  try {
    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.verifyGoogleToken(action.token);

    if (response.data) {
      yield put(updateLoading(false));
      if (response.data?.token)
        storage.setItem("accessToken", response.data?.token);
      let user: serviceAuthModels.response3dAuth = response.data;
      yield put(update3dthirdPart({ type: "google", response: user }));
      yield put(verifyGoogleSuccess(user));
      getMyProfileSaga();
    }
  } catch (error: any) {
    yield put(updateLoading(false));
  }
}

export function* verifyAppleTokenSaga(action: any) {
  try {
    yield put(updateLoading(true));

    let response: AxiosResponse = yield authApi.verifyAppleToken(action.token);

    if (response.data) {
      yield put(updateLoading(false));
      if (response?.data?.token)
        storage.setItem("accessToken", response?.data?.token);
      console.log("data response", response.data);
      let user: serviceAuthModels.response3dAuth = response.data;
      yield put(update3dthirdPart({ type: "apple", response: user }));
      yield put(verifyAppleSuccess(user));
      getMyProfileSaga();
    }
  } catch (error: any) {
    yield put(updateLoading(false));
  }
}
