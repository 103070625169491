import * as actionTypes from "./actionTypes";

/**
 * @param location
 * @returns update user loaction
 */

export const updateUserLocation = (location: any) => {
  return {
    type: actionTypes.UPDATE_USER_LOCATION,
    location,
  };
};

export const updateCartList = (cartItems:any) => ({
  type: actionTypes.UPDATE_CART_LIST,
  payload: cartItems,
});
export const updateCartListById = (cartItemsById:any) => ({
  type: actionTypes.UPDATE_CART_LIST_BY_ID,
  payload: cartItemsById,
});

export const updateTokens = (verifToken: any, signupToken: any, token: any) => {
  return {
    type: actionTypes.UPDATE_TOKENS,
    verifToken,
    signupToken,
    token,
  };
};

export const updateUserIpGeo = (location: any) => {
  return {
    type: actionTypes.SET_IP_GEO,
    location,
  };
};

/**
 * @param userInfo
 * @returns update user information
 */

export const updateUserAuth = (userInfo: any) => {
  return {
    type: actionTypes.UPDATE_USER_AUTH,
    userInfo,
  };
};

export const sendVerificationCode = (
  body: serviceAuthModels.SendVerificationCodeBody,
  resend: boolean
): serviceAuthModels.SendVerificationCodeAction => {
  return {
    type: actionTypes.SEND_VERIFICATION_CODE,
    body,
    resend,
  };
};
export const editUserError = (error: string) => {
  return {
    type: actionTypes.AUTH_ERROR_EDIT,
    error

  };
}

export const setTargetPageAfterLogin = (value: string) => {
  return {
    type: actionTypes.USER_REDIRECT_AFTER_LOGIN_TO,
    value
  };
}

export const verifyGoogle = (token: string) => {
  return {
    type: actionTypes.VERIFY_GOOGLE,
    token,
  };
};

export const verifyApple = (token: string) => {
  return {
    type: actionTypes.VERIFY_APPLE,
    token,
  };
};

export const verifyGoogleSuccess = (user: serviceAuthModels.response3dAuth) => {
  return {
    type: actionTypes.VERIFY_GOOGLE_SUCCESS,
    user,
  };
};

export const verifyAppleSuccess = (user: serviceAuthModels.response3dAuth) => {
  return {
    type: actionTypes.VERIFY_APPLE_SUCCESS,
    user,
  };
};

export const sendVerificationCodeSuccess = (
  response: serviceAuthModels.SendVerificationCodeResponse
): serviceAuthModels.SendVerificationCodeActionSuccess => {
  return {
    type: actionTypes.SEND_VERFICATION_CODE_SUCCESS,
    response,
  };
};

export const resendVerificationCodeSuccess = (
  response: serviceAuthModels.SendVerificationCodeResponse
): serviceAuthModels.SendVerificationCodeActionSuccess => {
  return {
    type: actionTypes.RESEND_VERFICATION_CODE_SUCCESS,
    response,
  };
};

export const verifyCode = (
  body: serviceAuthModels.verifyCodeBody
): serviceAuthModels.verifyCodeAction => {
  return {
    type: actionTypes.VERIFY_CODE,
    body,
  };
};

export const verifyCodeSuccess = (
  response: serviceAuthModels.verifyCodeResponse
): serviceAuthModels.verifyCodeActionSuccess => {
  return {
    type: actionTypes.VERIFY_CODE_SUCCESS,
    response,
  };
};

export const signup = (
  body: serviceAuthModels.signupBody
): serviceAuthModels.signupAction => {
  return {
    type: actionTypes.SINGUP,
    body,
  };
};

export const signupSuccess = (
  response: serviceAuthModels.signupResponse
): serviceAuthModels.signupActionSuccess => {
  return {
    type: actionTypes.SINGUP_SUCCESS,
    response,
  };
};

export const setUserMode = (userMode: number) => {
  return {
    type: actionTypes.SET_USER_MODE,
    userMode,
  };
};

export const authError = (error: string) => {
  return {
    type: actionTypes.AUTH_ERROR,
    error,
  };
};

export const resetUser = () => {
  return {
    type: actionTypes.RESET_USER,
  };
};

export const getmyprofile = () => {
  return {
    type: actionTypes.GET_MY_PROFILE,
  };
};

export const getmyprofileSuccess = (user: serviceAuthModels.user) => {
  return {
    type: actionTypes.GET_MY_PROFILE_SUCCESS,
    user,
  };
};

export const editMyProfile = (user: {
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
}) => {
  return {
    type: actionTypes.EDIT_MY_PROFILE,
    user,
  };
};

export const editmyprofileSuccess = (user: serviceAuthModels.user) => {
  return {
    type: actionTypes.EDIT_MY_PROFILE_SUCCESS,
    user,
  };
};

export const update3dthirdPart = (body: {
  type: "apple" | "google";
  response: serviceAuthModels.response3dAuth;
}) => {
  return {
    type: actionTypes.UPDATE_3D_THIRD_AUTH,
    body: body,
  };
};

export const logout = () => {
  return {
    type: actionTypes.USER_LOGOUT,
  };
};

export const clearStoreItem = (item: string) => {
  return {
    type: actionTypes.CLEAR_STORE_ITEM,
    item,
  };
};

/**
 * @param value
 * @returns Redux dispatch Action / Update Loader
 */

export const updateLoading = (value: boolean) => {
  return {
    type: actionTypes.UPDATE_LOADER,
    value,
  };
};
export const editmyprofileFailure = (errorMessage: string) => {
  return {
    type: actionTypes.EDIT_MY_PROFILE_FAILURE,
    errorMessage,
  };
};



export const getFiltredShops = (body: serviceShopModels.getFiltredShopBody) => {
  return {
    type: actionTypes.GET_FILTRED_SHOP,
    body,
  };
};

export const getFiltredShopsSuccess = (shops: any) => {
  return {
    type: actionTypes.GET_FILTRED_SHOP_SUCCESS,
    shops,
  };
};

export const changeShopMode = (value: number) => {
  return {
    type: actionTypes.CHANGE_SHOPS_MODE,
    value,
  };
};

export const addShopToFavorite = (idRestaurant: number, value: boolean) => {
  return {
    type: actionTypes.ADD_SHOP_TO_FAVORITE,
    idRestaurant,
    value,
  };
};

export const getFavoritesFiltredShopsSuccess = (
  restaurants: serviceShopModels.shop[]
) => {
  return {
    type: actionTypes.GET_FAVORITES_FILTRED_SHOP_SUCCESS,
    restaurants,
  };
};

export const getFavoritesFiltredShops = (
  body: serviceShopModels.getFiltredShopBody
) => {
  return {
    type: actionTypes.GET_FAVORITES_FILTRED_SHOP,
    body,
  };
};

export const changeFilterRestaurantsInput = (value: string) => {
  return {
    type: actionTypes.CHANGE_FILTER_RESTAURANTS_INPUT,
    value: value,
  };
};

//* categories
export const getAllCategories = () => {
  return {
    type: actionTypes.GET_CATEGORIES_LIST,
  };
};

export const getAllCategoriesSuccess = (
  categoriesList: serviceCategoryModels.getAllCategoriesResponse
) => {
  return {
    type: actionTypes.GET_CATEGORIES_LIST_SUCCESS,
    categoriesList: [...categoriesList.categories],
  };
};

export const getCategoryShopsListSuccess = (
  list: serviceShopModels.getFiltredShopResponse
) => {
  return {
    type: actionTypes.GET_CATEGORY_SHOP_LIST_SUCCESS,
    list: list.restaurants,
  };
};

export const categoryShopsChangeShopMode = (value: number) => {
  return {
    type: actionTypes.CHANGE_CATEGORY_SHOP_MODE,
    value: value,
  };
};

export const currentCategoryChange = (value: number) => {
  return {
    type: actionTypes.CHANGE_CURRENT_CATEGORY,
    value: value,
  };
};

export const getCategoryShopsList = (
  body: serviceShopModels.getFiltredShopBody
) => {
  return {
    type: actionTypes.GET_CATEGORY_SHOP_LIST,
    body: body,
  };
};

export const getRestaurantMenu = (body: serviceMenuModels.getMenuBody) => {
  return {
    type: actionTypes.GET_RESTAURANT_MENU,
    body: body,
  };
};

export const getRestaurantMenuSuccess = (
  menu: serviceMenuModels.getMenuResponse
): serviceMenuModels.getMenuActionResponse => {
  return {
    type: actionTypes.GET_RESTAURANT_MENU_SUCCESS,
    menu: menu.restaurant,
  };
};

export const setActiveRestaurant = (restaurant: any) => {
  return {
    type: actionTypes.SET_ACTIVE_RESTAURANT,
    restaurant: restaurant,
    modeTypes:
      restaurant.aEmporte && restaurant.surPlace
        ? 0
        : !restaurant.aEmporte && restaurant.surPlace
        ? 2
        : restaurant.aEmporte && !restaurant.surPlace
        ? 1
        : 0,
  };
};

export const getRestaurantMenuReset = () => {
  return {
    type: actionTypes.GET_RESTAURANT_MENU_RESET,
  };
};

export const selectCategory = (
  category: serviceMenuModels.Category,
  menuId: number,
  restaurantId: number,
  initialEditPrice?: number
) => {
  return {
    type: actionTypes.SELECT_CATEGORY,
    category,
    menuId,
    initialEditPrice,
    restaurantId,
  };
};

export const updateCurrentCategory = (
  category: serviceMenuModels.Category,
  menuId: number
) => {
  return {
    type: actionTypes.SELECT_CATEGORY,
    category,
    menuId,
  };
};

export const selectComposition = (
  product: serviceMenuModels.Product,
  productHasCompositionId: number,
  componentId: number
) => {
  return {
    type: actionTypes.SELECT_COMPOSITION,
    product,
    productHasCompositionId,
    componentId: componentId,
  };
};

export const getRestaurantChangeMode = (value: number) => {
  return {
    type: actionTypes.GET_RESTAURANT_CHANGE_MODE,
    value: value,
  };
};

//* skeleton
export const updateHomeLoading = (value: boolean) => {
  return {
    type: actionTypes.UPDATE_HOME_LOADING,
    value,
  };
};

export const updateCategoriesLoading = (value: boolean) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_LOADING,
    value,
  };
};

//* cart
export const updateCart = (obj: boolean) => {
  return {
    type: actionTypes.UPDATE_CART,
    obj,
  };
};

export const preSaveCart = (preSavedCart: any) => {
  return {
    type: actionTypes.PRE_SAVE_CART,
    preSavedCart,
  };
};

export const pushCategory = (category: serviceMenuModels.Category) => {
  return {
    type: actionTypes.PUSH_CATEGORY,
    category,
  };
};

export const saveCart = (obj: any) => {
  return {
    type: actionTypes.SAVE_CART,
    obj,
  };
};

export const updateSavedCart = (obj: any) => {
  return {
    type: actionTypes.UPDATE_SAVED_CART,
    obj,
  };
};

export const resetCategory = () => {
  return {
    type: actionTypes.RESET_CATEGORY,
  };
};

export const updateCurrentPrice = (newPrice: number, actionType: string) => {
  return {
    type: actionTypes.UPDATE_CURRENT_PRICE,
    newPrice,
    actionType,
  };
};


export const updateCategoryCount = (typeOfAction: string) => {
  console.log(typeOfAction);
  return {
    type: actionTypes.UPDATE_CATEGORY_COUNT,
    typeOfAction,
  };
};


export const updateCompositionsPrice = (
  newPrice: number,
  actionType: string
) => {
  return {
    type: actionTypes.UPDATE_COMPOSITION_PRICE,
    newPrice,
    actionType,
  };
};

export const selectOrder = (order: any) => {
  return {
    type: actionTypes.SELECT_ORDER,
    order,
  };
};

export const updateOrder = (obj: any) => {
  return {
    type: actionTypes.UPDATE_ORDER,
    obj,
  };
};

export const getOrdersList = () => {
  return {
    type: actionTypes.GET_ORDER_LIST,
  };
};

export const getOrdersListSuccess = (ordersList: {
  commandes: serviceCommandModels.commandeItem[];
}) => {
  return {
    type: actionTypes.GET_ORDER_LIST_SUCCESS,
    ordersList,
  };
};

export const getOrderById = (orderId: string) => {
  return {
    type: actionTypes.GET_ORDER_BY_ID,
    orderId,
  };
};

export const getOrderByIdSuccess = (orderDetails: any) => {
  return {
    type: actionTypes.GET_ORDER_BY_ID_SUCCESS,
    orderDetails,
  };
};

export const createPaymentAction = (order: any) => {
  return {
    type: actionTypes.CREATE_PAYMENT,
    order,
  };
};

export const createPaymentActionSuccess = (order: any) => {
  return {
    type: actionTypes.CREATE_PAYMENT_SUCCESS,
    order,
  };
};

export const confirmPaymentAction = (order: any) => {
  return {
    type: actionTypes.CONFIRM_PAYMENT,
    order,
  };
};

export const confirmPaymentActionSuccess = (order: any) => {
  return {
    type: actionTypes.CONFIRM_PAYMENT_SUCCESS,
    order,
  };
};
