import storage from "redux-persist/lib/storage";
import axios from "axios";
import { config } from "../config";

const baseUrl = config.api_baseUrl;
const authAxios = axios.create({});
const unAuthAxios = axios.create({});

unAuthAxios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

authAxios.interceptors.request.use(
  async (config: any) => {
    const accessToken = await storage.getItem("accessToken");
    //const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI0Y2JlYmM3NC01NTQwLTQyOGMtODA2Zi1jOGRjY2Y0NzJjMmEiLCJuYW1lIjoiU2FnaHJvdW4iLCJyb2xlIjoiIiwibmJmIjoxNzExMzcyMzI2LCJleHAiOjE3MTE5NzcxMjYsImlhdCI6MTcxMTM3MjMyNiwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.feb1bmyHVkaohdv_nmTR2AijcHtB6HhRL4WWNe5kRyc";
    config.headers = {
      ...config.headers,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
      
    };

    if (accessToken) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      };
    } else {
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
async function redirectToLogin() {
  try {
    await localStorage.removeItem("accessToken");
    await localStorage.removeItem("token");
    window.location.replace("/");
  } catch (error) {
    console.error(error);
  }
}
authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error.", error);
   
      // Handle token refresh here
      try {
       // redirectToLogin();
        return Promise.reject(error);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
  }
);

unAuthAxios.interceptors.response = authAxios.interceptors.response;

export const authApi = {
  sendVerificationCode: async (
    body: serviceAuthModels.SendVerificationCodeBody
  ) => {
    
    const accessToken = await storage.getItem("accessToken");
    let usedAxios = accessToken ? authAxios : unAuthAxios;
    const response= await usedAxios.post<serviceAuthModels.SendVerificationCodeResponse>(
      baseUrl + "/Account/SendVerificationCode",
      body
    );
 console.log("sendVerificationCode response",response)
    return response
  },
  verifyCode: async (body: serviceAuthModels.verifyCodeBody) => {
    const accessToken = await storage.getItem("accessToken");
    let usedAxios = accessToken ? authAxios : unAuthAxios;
    return usedAxios.post<serviceAuthModels.verifyCodeResponse>(
      baseUrl + "/Account/VerifyCode",
      body
    );
  },
  verifyGoogleToken: async (token: string) => {
    return unAuthAxios.post(baseUrl + "/Account/verify-google?isWeb=true", {
      tokenId: token,
    });
  },
  verifyAppleToken: async (token: string) => {
    return unAuthAxios.post(baseUrl + "/Account/verify-apple", {
      tokenId: token,
    });
  },
  signup: (body: serviceAuthModels.signupBody) => {
    return unAuthAxios.post<serviceAuthModels.verifyCodeResponse>(
      baseUrl + "/Account/SignUp",
      body
    );
  },
  getmyprofile: () => {
    return authAxios.get(baseUrl + "/Account/GetUser");
  },
  editmyprofile: (user: {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
  }) => {
    return authAxios.post(baseUrl + "/Account/EditUser", { ...user });
  },
};

export const shopApi = {
  getFiltredShop: (body: serviceShopModels.getFiltredShopBody) => {
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(
      baseUrl + "/Restaurants",
      body
    );
  },
  getFavoritesFiltredShop: (body: serviceShopModels.getFiltredShopBody) => {
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(
      baseUrl + "/Restaurants",
      { ...body, favoris: true }
    );
  },
  getCategoriesList: () => {
    return unAuthAxios.get<any>(baseUrl + "/Restaurants/list-categories");
  },
  getShopMenu: (body: serviceMenuModels.getMenuBody) => {
    return unAuthAxios.post<serviceMenuModels.getMenuResponse>(
      baseUrl + "/Restaurants/GetByIdIncludeMenu",
      body
    );
  },
  getResturantById: (body: serviceMenuModels.getMenuBody) => {
    return unAuthAxios.post<serviceMenuModels.getMenuResponse>(
      baseUrl + "/Restaurants/GetById",
      body
    );
  },
  addShopToFavorite: (idRestaurant: number, value: boolean) => {
    return authAxios.post(
      baseUrl +
        "/Restaurants/favorite?idRestaurant=" +
        idRestaurant +
        "&isFavorite=" +
        value
    );
  },
  searchByKey: (key: string, body: serviceShopModels.getFiltredShopBody) => {
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(
      baseUrl + "/Restaurants",
      { ...body, nom: key }
    );
  },
};

export const orderApi = {
  getOrdersList: () => {
    return authAxios.get(baseUrl + "/Commandes");
  },
  getOrderById: (commandId: string) => {
    return authAxios.get(baseUrl + "/Commandes/CommandeById?id=" + commandId);
  },
};

export const paymentApi = {
  createPayment: (body: servicePaymentModule.createPayment) => {
    return authAxios.post(baseUrl + "/create", body);
  },
  confirmPayment: (body: servicePaymentModule.confirmpayment) => {
    return authAxios.post(baseUrl + "/confirm", body);
  },
};
