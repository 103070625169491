import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isloading: false,
};

function reducer(state = initialState, action: any) {
    switch (action.type) {
        case actionTypes.UPDATE_LOADER:
            return {
                ...state,
                isloading: action.value,
            }

        default:
            return state;
    }
}

export default reducer;