import * as actionTypes from "../actions/actionTypes";

const initialState = {
  selectedOrder: null,
  orders: null,
  orderDetails: null,
  cartList: [],
  cartListById: [],
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SELECT_ORDER:
      return {
        ...state,
        selectedOrder: action.order,
      };

    case actionTypes.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        orders: action.ordersList,
      };

    case actionTypes.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orderDetails: action.orderDetails,
      };
    case actionTypes.UPDATE_CART_LIST:
      return {
        ...state,
        cartList: action.payload,
      };
    case actionTypes.UPDATE_CART_LIST_BY_ID:
      return {
        ...state,
        cartList: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;
