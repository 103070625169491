import React, { useEffect, useState } from "react";
import UserAvatar from "../../images/Avatar-user-profile.svg";
import MyprofileIcon from "../../images/MyProfile.svg";
import MesCommandesIcon from "../../images/mes-commandes.svg";
import "./profile.scss";
import { connect } from "react-redux";
import withNavigateHook from "../WithNavigation";
import { clearStoreItem, logout } from "../../store/actions/actions";

const Profile = (props: any) => {
  const [show, setShowUserBox] = useState(false);
  const [userWantToLogout, setuserWantToLogout] = useState(0);

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    props.clearItemStore("auth3dthird");
    props.clearItemStore("authGoogleSuccess");
    props.clearItemStore("authAppleSuccess");
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }

    props.logout();
    if (props.setLogoutRetries !== undefined) {
      props.setLogoutRetries(Math.random() * 26);
    }
    props.navigate("/");
  };
  const handleGoToProfile = () => {
    props.navigate("/mon-profile");
  };
  const handleGoToMesCommandes = () => {
    props.navigate("/mes-commandes");
  };
  return (
    <div className="user-profile">
      <img
        className="toggle-btn-profile"
        onClick={() => setShowUserBox(!show)}
        src={UserAvatar}
        alt=""
      />
      <div className={`user-list-box ${show ? "showBox" : ""}`}>
        <div
          onClick={() => setShowUserBox(!show)}
          className="overlay-box"
        ></div>
        <div className="User-menu">
          <ul>
            <li onClick={handleGoToProfile}>
              {" "}
              <img src={MyprofileIcon} alt="" />
              <p>Mon profile</p>
            </li>
            <span className="diver"></span>
            <li onClick={handleGoToMesCommandes}>
              {" "}
              <img src={MesCommandesIcon} alt="" />
              <p>Mes commandes</p>
            </li>
            <span className="diver"></span>
          </ul>
          <p className="logout" onClick={logoutUser}>
            Sign Out
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    token: state.auth.token,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Profile));
