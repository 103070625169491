import React from "react";
import { config } from "./config";
import moment from "moment";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./navigation";
import configureStore from "./store/store";
import { persistStore } from "redux-persist";

import "./style.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoadScript } from "@react-google-maps/api";
import {APIProvider, Map} from '@vis.gl/react-google-maps';


function App() {
  moment.locale("fr");

  const store = configureStore({});
  const persistor = persistStore(store);
  
//localStorage.clear()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
 

     
        <GoogleOAuthProvider clientId="634820308489-qa4lnj89noo3b9a4gejp0bgob0t0to61.apps.googleusercontent.com">
          <LoadScript
            libraries={["places"]}
            id={"TheOneAndOnlyGmaps"}
            language={"fr"}
            region={"FR"}
            googleMapsApiKey={"AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU"}
          >
            <div className="container">
            
                <AppRoutes />
              
            </div>
          </LoadScript>
        </GoogleOAuthProvider>

      </PersistGate>
    </Provider>
  );
}

export default App;
