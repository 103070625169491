

export const AppConfigProd = {
    api_timeout: 5000,
    app_version: "0.0.1",
    mode: "prod",
    api_baseUrl: "https://fioeatbackendprod.azurewebsites.net",
    api_baseUrl_Picture: "https://fiomanager.frenchinnov.fr/uploads/",
    key_google: "AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU",
    success_timout_animation: 700,
    mapObject: {
        key: "AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU",

        language: 'fr',
        components: 'country:fr',
        type:"address"
    },
    stripe_public_key:"pk_test_51L2tUQJv3gyYkBIAk6EX4HwZ8torLzeDDOIaLaM34h2p1Q5bscUfr0PT81VxJko9RZAvUxRnXyscFJBztQJERzp700pRikLb08"
}