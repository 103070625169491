import { Chip, Typography } from "@mui/joy";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { grey, orange } from "@mui/material/colors";
import _ from "lodash";
import {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
  memo,
} from "react";
import "./style.scss";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClippedDrawer from "../../components/Cart/index";
import CompositionDialog from "../../components/menuComponents/compositionDialog";
import withNavigateHook from "../../components/WithNavigation";
import LeftArrow from "../../images/arrow-left_grey.svg";
import MinusOrangeIcon from "../../images/MinusOrange.svg";
import PlusboxOrangeIcon from "../../images/plusboxOrange.svg";
import {
  getRestaurantMenu,
  preSaveCart,
  pushCategory,
  resetCategory,
  saveCart,
  selectComposition,
  updateCart,
  updateCategoryCount,
  updateCompositionsPrice,
  updateCurrentCategory,
  updateCurrentPrice,
} from "../../store/actions/actions";

import NavBar from "../../components/Nbar/Nav-bar";
import { shopApi } from "../../services/apiGate";

function calculatePriceForUnpairedQuantity(
  quantity: number,
  pricePerUnit: number
) {
  if (quantity % 2 === 0) {
    const pairedQuantity = Math.floor(quantity / 2);
    return pricePerUnit * pairedQuantity;
  } else {
    // Quantity is odd, calculate price for unpaired item
    const unpairedQuantity = Math.floor(quantity / 2) + 1;
    const totalPrice = unpairedQuantity * pricePerUnit;

    return totalPrice;
  }
}

const QuantityCounter = (props: any) => {
  const {
    maxSelection,
    item,
    val,
    index,
    indexxe,
    quantityArray,
    currentPrice,
    selectCompositions,
    updateQuantityArray,
    globalMaxSelection,
    price,
    changeCategory,
    updateCurrentPrice,
    updateCompositionsPrice,
  } = props;

  const [product, setProduct] = useState<serviceMenuModels.Product>(item);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    item?.components?.length === 1 ? item?.components?.[0].name : ""
  );
  const [comp, setComp] = useState({});
  const globalCount = () => {
    let sum = 0;

    for (let i = 0; i < quantityArray.length; i++) {
      sum += quantityArray[i];
    }
    return sum;
  };

  const handleSelect = useCallback(
    async (
      componentId: number,
      typeOfAction: string,
      hasComposition: boolean,
      id: number,
      isRadio?: boolean
    ) => {
      let currentProduct = product;

      if (isRadio) {
        currentProduct.numberOfSelection = 0;

        await Promise.all(
          currentProduct.components.map((el: any, i: any) => {
            if (el.numberOfSelection > 0) {
              if (el.hasCompositions) {
                el.compositions.map((comp: any, i: any) => {
                  comp.numberOfSelection = 0;
                });
              }

              let price = el.price;

              updateCurrentPrice(price, "DECREASE");
            }

            el.numberOfSelection = 0;
          })
        );
      }

      if (!hasComposition || hasComposition) {
        if (typeOfAction === "INCREASE") {
          let components = product.components;
          let toChangeComponent = components[componentId];

          updateCurrentPrice(toChangeComponent.price, typeOfAction);

          if (!toChangeComponent.numberOfSelection)
            toChangeComponent.numberOfSelection = 0;

          if (!currentProduct.numberOfSelection) {
            currentProduct.numberOfSelection = 0;
          }

          if (
            toChangeComponent.numberOfSelection <
              toChangeComponent.maxSelection &&
            currentProduct.numberOfSelection < currentProduct.maxSelection
          ) {
            toChangeComponent.numberOfSelection =
              toChangeComponent.numberOfSelection + 1;
            components[componentId] = toChangeComponent;

            let chagedProduct: serviceMenuModels.Product = {
              ...currentProduct,
              numberOfSelection: currentProduct.numberOfSelection + 1,
              components: components,
            };

            setProduct({ ...chagedProduct });
            changeCategory(chagedProduct, id);
          }
        } else if (typeOfAction === "DECREASE") {
          let components = product.components;
          let toChangeComponent = components[componentId];

          updateCurrentPrice(toChangeComponent.price, typeOfAction);

          if (
            toChangeComponent.numberOfSelection > 0 &&
            currentProduct.numberOfSelection > 0
          ) {
            toChangeComponent.numberOfSelection =
              toChangeComponent.numberOfSelection - 1;
            components[componentId] = toChangeComponent;

            let chagedProduct: serviceMenuModels.Product = {
              ...currentProduct,
              numberOfSelection: currentProduct.numberOfSelection - 1,
              components: components,
            };

            setProduct({ ...chagedProduct });
            changeCategory(chagedProduct, id);
          }
        }
      }
    },
    []
  );

  const handleOpenCompositionModal = (componentId: number, val: any) => {
    setComp(val);
    selectCompositions(val, index, componentId);
    setOpen(true);
  };
  const handleParagraphClick = (selectedRadio: any) => {
    setValue(selectedRadio);
  };
  // console.log("checj", props.item?.components[index].compositions);

  return (
    <div
      id={"product" + product.id}
      className="multiselectionCounter row"
      style={{
        fontSize: "14px",
        fontWeight: "400",
        alignItems: "center",
        color: "#344054",
        justifyContent: "flex-end",
      }}
    >
      <span
        style={{
          width: quantityArray?.[index] > 0 ? "50px" : "0px",
          overflowX: "hidden",
          transitionDuration: "700ms ",
        }}
        className="row all-center"
      >
        <IconButton
          size="small"
          disabled={quantityArray?.[index] === 0}
          onClick={() => {
            if (quantityArray[index] > 0) {
              updateQuantityArray(quantityArray[index] - 1, index);
              //updateCurrentPrice(price, "DECREASE");
              handleSelect(index, "DECREASE", props.hasCompositions, indexxe);
            }
          }}
          className={quantityArray?.[index] === 0 ? "hide" : ""}
        >
          <RemoveCircleOutlineRoundedIcon fontSize="medium" />
        </IconButton>

        {/* {props.item?.components[index]?.numberOfSelection
          ? props.item?.components[index]?.numberOfSelection
          : 0} */}

        {quantityArray[index] > 9
          ? quantityArray[index]
          : "0" + quantityArray[index]}
      </span>

      <IconButton
        size="small"
        disabled={
          quantityArray[index] === maxSelection ||
          globalCount() === globalMaxSelection
        }
        onClick={() => {
          if (quantityArray[index] < maxSelection) {
            updateQuantityArray(quantityArray[index] + 1, index);
            //updateCurrentPrice(price, "INCREASE");
            handleSelect(index, "INCREASE", props.hasCompositions, indexxe);
          }
        }}
        className={quantityArray?.[index] === maxSelection ? "hide" : ""}
      >
        <AddCircleOutlineRoundedIcon fontSize="medium" />
      </IconButton>

      {/* {val.hasCompositions && (
        <IconButton
          onClick={() => {
            handleOpenCompositionModal(index, val);
          }}
        >
          <ChevronRightRoundedIcon />
        </IconButton>
      )}

      {val.hasCompositions && (
        <Collapse
          in={value === val.name}
          className="all-center tableComposition"
          style={{ width: "70%" }}
        >
          <Stack
            className="compositionPreview full-width"
            spacing={0.5}
            direction={"column"}
          >
            <Stack direction={"column"} spacing={0.7}>
              {val.compositions?.map(
                (comp: any, i: number) =>
                  (comp.numberOfSelection > 0 ||
                    (comp.isDefault && !comp.defaultHidden)) && (
                    <p key={i} className="row tableItem">
                      {comp.numberOfSelection ? comp.numberOfSelection : 1}{" "}
                      {comp.name.toLowerCase()}{" "}
                      {comp.price ? "+" + comp.price + "€" : null}
                    </p>
                  )
              )}
            </Stack>

            <Divider />
            <Button
              onClick={() => handleOpenCompositionModal(index, val)}
              variant="text"
              size="small"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <small style={{ color: "#344054" }}>
                Modifier votre sélection{" "}
              </small>
              <ChevronRightRoundedIcon style={{ fill: "#344054" }} />
            </Button>
          </Stack>
        </Collapse>
      )} */}

      {open && (
        <CompositionDialog
          index={index}
          openState={{ open: open, setOpen: setOpen }}
          component={comp}
          value={value}
          setValue={setValue}
          handleParagraphClick={handleParagraphClick}
          obj={item}
        />
      )}
    </div>
  );
};

const MultipleSelection = (props: any) => {
  const {
    item,
    index,
    showText,
    isOptional,
    updateCurrentPrice,
    changeCategory,
    updateCompositionsPrice,
    setSelectedComponents,
    selectedComponents,
    currentPrice,
    hasObligation,
  } = props;
  //quantity of each component : init at 0
  const [quantityArray, setQuantityArray] = useState(
    Array(item?.components?.length).fill(0)
  );

  //array dispatcher with new ref to trigger re-rendering
  const updateQuantityArray = (newQ: number, index: number) => {
    const aux = Array.from(quantityArray);
    aux[index] = newQ;
    setQuantityArray(aux);
  };

  const globalCount = () => {
    let sum = 0;

    for (let i = 0; i < quantityArray.length; i++) {
      sum += quantityArray[i];
    }

    return sum;
  };

  const [product, setProduct] = useState<serviceMenuModels.Product>(item);

  const handleSelect = useCallback(
    async (
      componentId: number,
      typeOfAction: string,
      hasComposition: boolean,
      id: number,
      isRadio?: boolean
    ) => {
      let currentProduct = product;

      if (isRadio) {
        currentProduct.numberOfSelection = 0;

        await Promise.all(
          currentProduct.components.map((el: any, i: any) => {
            if (el.numberOfSelection > 0) {
              if (el.hasCompositions) {
                el.compositions.map((comp: any, i: any) => {
                  comp.numberOfSelection = 0;
                });
              }

              let price = el.price;

              updateCurrentPrice(price, "DECREASE");
            }

            el.numberOfSelection = 0;
          })
        );
      }

      if (!hasComposition) {
        if (typeOfAction === "INCREASE") {
          let components = product.components;
          let toChangeComponent = components[componentId];

          updateCurrentPrice(toChangeComponent.price, typeOfAction);

          if (!toChangeComponent.numberOfSelection)
            toChangeComponent.numberOfSelection = 0;

          if (!currentProduct.numberOfSelection) {
            currentProduct.numberOfSelection = 0;
          }

          if (
            toChangeComponent.numberOfSelection <
              toChangeComponent.maxSelection &&
            currentProduct.numberOfSelection < currentProduct.maxSelection
          ) {
            toChangeComponent.numberOfSelection =
              toChangeComponent.numberOfSelection + 1;
            components[componentId] = toChangeComponent;

            let chagedProduct: serviceMenuModels.Product = {
              ...currentProduct,
              numberOfSelection: currentProduct.numberOfSelection + 1,
              components: components,
            };

            setProduct({ ...chagedProduct });
            changeCategory(chagedProduct, id);
          }
        } else if (typeOfAction === "DECREASE") {
          let components = product.components;
          let toChangeComponent = components[componentId];

          updateCurrentPrice(toChangeComponent.price, typeOfAction);

          if (
            toChangeComponent.numberOfSelection > 0 &&
            currentProduct.numberOfSelection > 0
          ) {
            toChangeComponent.numberOfSelection =
              toChangeComponent.numberOfSelection - 1;
            components[componentId] = toChangeComponent;

            let chagedProduct: serviceMenuModels.Product = {
              ...currentProduct,
              numberOfSelection: currentProduct.numberOfSelection - 1,
              components: components,
            };

            setProduct({ ...chagedProduct });
            changeCategory(chagedProduct, id);
          }
        }
      }
    },
    []
  );

  return (
    <div id={"product" + product.id}>
      <div className="multiSelectionContainer">
        <div className="sectionHeader row" style={{ alignItems: "center" }}>
          <Typography
            style={{
              fontFamily: "Poppins,sans-serif",
              fontSize: "14px",
              fontWeight: 700,
              color: "#1D2939",
              backgroundColor: "#F6F7F9",
            }}
          >
            {product?.name?.toLowerCase()}
          </Typography>
          <Typography>
            {props.hasObligation(product.components) ? (
              !(
                product?.numberOfSelection >= 0 ||
                product?.numberOfSelection == undefined
              ) ? (
                props.showText ? (
                  <div className="obligatoir">obligatoire</div>
                ) : null
              ) : (
                <div className="obligatoir">obligatoire</div>
              )
            ) : product.maxSelection > 0 &&
              product.components[0]?.name !== "SUIVANT" ? (
              <div className="maxSelection">
                <Typography>Max . {product?.maxSelection}</Typography>
              </div>
            ) : null}
          </Typography>
          {!isOptional && <div></div>}{" "}
        </div>

        <Stack
          className="multiSelectionOptionsContainer full-width"
          direction={"column"}
          spacing={1}
        >
          {product?.components?.map((val: any, i: number) => {
            return val?.isNextButton ? null : (
              <div
                key={i}
                className="row"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: `${val?.isOutOfStock ? "gray" : "#1D2939"}`,
                    }}
                  >
                    {val?.name.toLowerCase()}
                  </Typography>
                  {val?.price !== 0 ? (
                    <Typography
                      style={{
                        color: "#475467",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      +{val?.price}€
                    </Typography>
                  ) : null}
                </div>

                {val.maxSelection === 1 ? (
                  <>
                    <Checkbox
                      className="checkboxEl"
                      sx={{
                        color: grey[500],
                        "&.Mui-checked": {
                          color: orange[600],
                        },
                      }}
                      value={quantityArray[i]}
                      disabled={
                        (globalCount() === product.maxSelection &&
                          quantityArray[i] === 0) ||
                        val.isOutOfStock
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          updateQuantityArray(quantityArray[i] + 1, i);
                          handleSelect(
                            i,
                            "INCREASE",
                            val.hasCompositions,
                            index
                          );
                        } else {
                          updateQuantityArray(quantityArray[i] - 1, i);
                          handleSelect(
                            i,
                            "DECREASE",
                            val.hasCompositions,
                            index
                          );
                        }
                      }}
                    />
                  </>
                ) : (
                  <QuantityCounter
                    index={i}
                    indexxe={index}
                    val={val}
                    item={item}
                    handleSelect={handleSelect}
                    hasCompositions={val.hasCompositions}
                    updateCurrentPrice={updateCurrentPrice}
                    currentPrice={currentPrice}
                    updateCompositionsPrice={updateCompositionsPrice}
                    price={val.price}
                    maxSelection={val.maxSelection}
                    quantityArray={quantityArray}
                    updateQuantityArray={updateQuantityArray}
                    globalMaxSelection={product.maxSelection}
                    changeCategory={changeCategory}
                    selectCompositions={props.selectCompositions}
                  />
                )}
              </div>
            );
          })}
        </Stack>
      </div>
      <Divider />
    </div>
  );
};

const UniSelection = (props: any) => {
  const {
    obj,
    index,
    isOptional,
    changeCategory,
    updateCurrentPrice,
    hasObligation,
    selectCompositions,
  } = props;

  const nameLable =
    obj.components[0].name === "SUIVANT" ? (
      <div></div>
    ) : (
      <div
        style={{
          fontFamily: "Poppins,sans-serif",
          fontSize: "14px",
          fontWeight: 700,
          color: "#1D2939",
        }}
      >
        {" "}
        {obj.name}
      </div>
    );
  const [isExpanded, setIsExpanded] = useState(true); // State to manage collapse, initialized as true

  // Function to toggle collapse
  const toggleCollapse = () => {
    setIsExpanded(!isExpanded); // Toggles the state
  };

  const [value, setValue] = useState(obj?.name !== "MENU" ? false : true);

  //state to control the dialog open/closed
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<serviceMenuModels.Product>(obj);
  const [comp, setComp] = useState({});
  const [radiprice, setRadiprice] = useState();
  const [theRadio, setTheRadio] = useState();
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);

  const handleSelect = useCallback(
    async (
      componentId: number,
      typeOfAction: string,
      hasComposition: boolean,
      id: number,
      isRadio?: boolean
    ) => {
      try {
        let currentProduct = product;

        if (isRadio) {
          currentProduct.numberOfSelection = 0;

          await Promise.all(
            currentProduct?.components?.map((el: any, i: any) => {
              if (el?.numberOfSelection > 0) {
                let price = el?.price;
                updateCurrentPrice(
                  price + (el?.compositionsTotalPrice ?? 0),
                  "DECREASE"
                );
                if (el?.hasCompositions) {
                  el?.compositions.map((comp: any, i: any) => {
                    comp.numberOfSelection = 0;

                    //  comp.compositionsTotalPrice=0
                  });
                }
              }
              el.compositionSelection = 0;
              el.compositionsTotalPrice = 0;
              el.numberOfSelection = 0;
            })
          );
        }
        if (true) {
          if (typeOfAction === "INCREASE") {
            let components = product.components;

            let toChangeComponent = components[componentId];
            updateCurrentPrice(toChangeComponent.price, typeOfAction);
            if (!toChangeComponent?.numberOfSelection)
              toChangeComponent.numberOfSelection = 0;
            if (!currentProduct?.numberOfSelection) {
              currentProduct = {
                ...currentProduct,
                numberOfSelection: 0,
              };
            }
            if (toChangeComponent?.numberOfSelection == undefined) {
              toChangeComponent = {
                ...toChangeComponent,
                numberOfSelection: 0,
              };
            }

            if (
              toChangeComponent?.numberOfSelection <
                toChangeComponent?.maxSelection &&
              currentProduct?.numberOfSelection < currentProduct?.maxSelection
            ) {
              toChangeComponent.numberOfSelection =
                toChangeComponent.numberOfSelection + 1;
              components[componentId] = toChangeComponent;
              let chagedProduct: serviceMenuModels.Product = {
                ...currentProduct,
                numberOfSelection: currentProduct.numberOfSelection + 1,
                components: components,
              };

              setProduct({ ...chagedProduct });

              changeCategory(chagedProduct, id);
            }
          } else if (typeOfAction === "DECREASE") {
            let components = product.components;
            let toChangeComponent = components[componentId];
            updateCurrentPrice(toChangeComponent.price, typeOfAction);

            if (
              toChangeComponent.numberOfSelection > 0 &&
              currentProduct.numberOfSelection > 0
            ) {
              toChangeComponent.numberOfSelection =
                toChangeComponent.numberOfSelection - 1;
              components[componentId] = toChangeComponent;
              let chagedProduct: serviceMenuModels.Product = {
                ...currentProduct,
                numberOfSelection: currentProduct.numberOfSelection - 1,
                components: components,
              };

              setProduct({ ...chagedProduct });
              changeCategory(chagedProduct, id);
            }
          }
        }
      } catch (error) {
        console.error("Error in handleSelect:", error);
      }
    },
    []
  );

  const handleOpenCompositionModal = useCallback(
    (componentId: number, val: any) => {
      setComp(val);
      selectCompositions(obj, index, componentId);
      setOpen(true);
    },
    []
  );

  const handleParagraphClick = (selectedRadio: any) => {
    setValue(selectedRadio);
  };

  return (
    <div id={"product" + product.id}>
      <div className="uniSelectionContainer">
        <div
          className="sectionHeader row"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Stack direction={"row"} spacing={1}>
            <Typography>
              {nameLable}
              {/* {obj.name?.toLowerCase()} */}
            </Typography>
            <Typography>
              {props.hasObligation(product.components) ? (
                !(
                  product?.numberOfSelection >= 0 ||
                  product?.numberOfSelection == undefined
                ) ? (
                  props.showText ? (
                    <div className="obligatoir">obligatoire</div>
                  ) : null
                ) : (
                  <div className="obligatoir">obligatoire</div>
                )
              ) : product.maxSelection > 0 &&
                product.components[0]?.name !== "SUIVANT" ? (
                <div className="maxSelection">
                  <Typography>Max . {product?.maxSelection}</Typography>
                </div>
              ) : null}
            </Typography>
            {!isOptional && <div></div>}{" "}
            {/* here to add the logic that triggers the error flags => maybe a state that goes false when submitting without filling a requierd field*/}
          </Stack>
        </div>

        <RadioGroup
          value={value}
          onChange={(e) => {
            const selectedRadio = e.target.value;

            handleParagraphClick(selectedRadio);
          }}
        >
          {obj.components?.map((val: any, i: number) => {
            return (
              <Fragment>
                {val?.isNextButton ? null : (
                  <div
                    className="componentContainer full-width column"
                    hidden={!(val.name === "SUIVANT")}
                    style={{ alignItems: "center" }}
                  >
                    <div className="componentRow full-width">
                      <FormControlLabel
                        //   defaultChecked={val.numberOfSelection > 0}
                        value={val.name}
                        checked={val.numberOfSelection > 0}
                        control={
                          isOptional ? (
                            <Radio
                              className="radioIcon"
                              disabled={val.isOutOfStock}
                              size="small"
                              // checkedIcon={<CheckCircleRoundedIcon />}
                              // icon={<RadioButtonUncheckedRoundedIcon />}
                            />
                          ) : (
                            <Radio
                              className="radioIcon"
                              size="small"
                              disabled={val.isOutOfStock}
                            />
                          )
                        }
                        label={
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Poppins,sans-serif",
                                fontSize: "12px",
                                fontWeight: 600,
                                color: `${val.isOutOfStock ? "gray" : "#1D2939"}`,
                              }}
                            >
                              {val.name.toLowerCase()}
                            </Typography>
                            {val.price !== 0 ? (
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "#475467",
                                }}
                              >
                                +{val.price}€
                              </Typography>
                            ) : null}
                          </div>
                        }
                        labelPlacement="start"
                        className=""
                        style={{
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                        onChange={(e: any) => {
                          // if(value === val.name && isOptional) {setValue(null)}

                          if (e.target.checked)
                            handleSelect(
                              i,
                              "INCREASE",
                              val.hasCompositions,
                              index,
                              true
                            );
                        }}
                      />

                      {val.hasCompositions && (
                        <IconButton
                          onClick={() => {
                            handleOpenCompositionModal(i, val);
                          }}
                        >
                          <div className="btnArrow">
                            <ChevronRightRoundedIcon />
                          </div>
                        </IconButton>
                      )}
                    </div>

                    {val.hasCompositions && (
                      <Collapse
                        in={
                          (props.order.products[0].components.length === 1 &&
                            props.order.products[0].components[0].compositions.some(
                              (comp: any) =>
                                comp.isDefault === true ||
                                comp.isDefault === false
                            )) ||
                          value === val.name
                        }
                        //in={value === val.name}
                        className="all-center tableComposition"
                        style={{ width: "70%" }}
                      >
                        <Stack
                          className="compositionPreview full-width"
                          spacing={0.5}
                          direction={"column"}
                        >
                          <Stack direction={"column"} spacing={0.7}>
                            {val.compositions?.map(
                              (comp: any, i: number) =>
                                (comp.numberOfSelection > 0 ||
                                  (comp.isDefault && !comp.defaultHidden)) && (
                                  <p key={i} className="row tableItem">
                                    {comp.numberOfSelection
                                      ? comp.numberOfSelection
                                      : 1}{" "}
                                    {comp.name.toLowerCase()}{" "}
                                    {comp.price ? "+" + comp.price + "€" : null}
                                  </p>
                                )
                            )}
                          </Stack>

                          <Divider />
                          <Button
                            onClick={() => {
                              toggleCollapse();
                              handleOpenCompositionModal(i, val);
                            }}
                            variant="text"
                            size="small"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <small style={{ color: "#344054" }}>
                              Modifier votre sélection{" "}
                            </small>
                            <ChevronRightRoundedIcon
                              style={{ fill: "#344054" }}
                            />
                          </Button>
                        </Stack>
                      </Collapse>
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
          {open && (
            <CompositionDialog
              index={index}
              openState={{ open: open, setOpen: setOpen }}
              component={comp}
              value={value}
              setValue={setValue}
              handleParagraphClick={handleParagraphClick}
              obj={obj}
            />
          )}
        </RadioGroup>
      </div>
      <Divider />
    </div>
  );
};

function OrderView(props: any) {
  //const order = props.location.state;
  const [reductionCheckBox, setReductionCheckBox] = useState<boolean>(false);
  const [categoryCount, setcategoryCount] = useState<number>(1);
  const [addedToCart, setaddedToCart] = useState(false);
  const [openAllg, setOpenAllg] = useState<boolean>(false);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [obligationAlert, setobligationAlert] = useState(false);
  const [showText, setShowText] = useState(true);
  const [category, setcategory] = useState<serviceMenuModels.Category>();
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [cartList, setcartList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newOrder, setNewOrder] = useState<any>();

  //const [order, setOrder] = useState<any>(newOrder);
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const stackRef = useRef<HTMLDivElement>(null);
  let resId = props.location?.pathname?.split("/")[2];
  let menuId = parseInt(props.location?.pathname?.split("/")[4]);
  let targetId = parseInt(props.location?.pathname?.split("/")[6]);
  const [order, setOrder] = useState<any>(props.location.state);

  //handle the showing the item when you copy the url


  window.addEventListener("popstate", (event) => {
    props.navigate(`/restaurant/${resId}`);
  });


  useEffect(() => {
    const fetchRestaurants = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://fioeatbackend.azurewebsites.net/Restaurants/GetByIdIncludeMenu",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              diningMode: props.mode,
              adresse: {
                latitude: props.locations.lat,
                longitude: props.locations.lng,
              },
              id: resId,
            }),
          }
        );
        if (!response.ok) {
        }
        const data = await response.json();
        let category = data?.restaurant?.menus
          .filter((menu: any) => menu.id == menuId)[0]
          ?.categories?.filter((menu: any) => menu?.id == targetId)[0];
        setOrder({ ...category });
      } catch (error) {
        setIsLoading(false);
        console.log("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!order) {
      fetchRestaurants();
    }
  }, [order, resId, menuId, targetId, props.mode, props.locations]);

  useEffect(() => {
    if (
      order &&
      order.products &&
      order.products.length > 0 &&
      order.products[0].components
    ) {
      const MenuIsTrue = order?.products[0]?.name === "BASE" && "MENU";
      const componentsLength = order.products[0].components.length;
      const componentsLength2 = order?.products;

      // Check if components exist and has a length of 1
      if (componentsLength === 1) {
        const price = order.products[0].components[0].price;
        props.updateCurrentPrice(price, "INCREASE");
        order.products[0].components[0].numberOfSelection = 1;
      }
    }
  }, [order]);

  //   const __updateCart = () => {
  //     try {
  //         // if (modeEdit) {
  //         //     seteditRestauranId(props.activeRestaurantId)

  //         //     let { restaurant, mode } = props.route.params
  //         //     props.preSaveCart({
  //         //         category: props.selectedCategory,

  //         //         restaurant: restaurant,
  //         //         mode: mode
  //         //     })
  //         //     props.updateCurrentPrice(props.currentPrice, 'DECREASE')
  //         //     props.updateCurrentPrice((props.selectedCategory.amount), 'INCREASE')
  //         // }
  //        // else {

  //             let restaurant = props.menu
  //             props.preSaveCart({
  //                 category: props.selectedCategory,

  //                 restaurant: restaurant,
  //                 mode: props.mode
  //             })
  //             //  props.updateCurrentPrice(props.currentPrice, 'DECREASE')
  //             //  props.updateCurrentPrice((props.selectedCategory.amount), 'INCREASE')

  //         //}
  //         //  props.preSaveCart(modeEdit ? props.selectedCategory : props.selectedCategory, props.mode, props.menuId)
  //     } catch (error) {

  //     }
  // }
  // useEffect(() => {

  //   __updateCart()
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shopDataString = localStorage.getItem("shop");

  const datashop = shopDataString ? JSON.parse(shopDataString) : null;

  const openCart = () => {
    if (props.savedCategories && Object.values(props.savedCategories)[0])
      setCartOpen(true);
  };

  const isOptional = (obj: any) => {
    if (obj && obj.components && Array.isArray(obj.components)) {
      // Check if at least one component has isNextButton as true
      const hasTrueComponent = obj.components.some(
        (component: any) => component.isNextButton === true
      );

      return hasTrueComponent;
    }

    // If the structure doesn't match or no components found, consider it non-optional
    return false;
  };

  useEffect(() => {
    let category: serviceMenuModels.Category = props.selectedCategory;
    let restaurant: serviceMenuModels.Restaurant = props.menu;
    let newCategory = category;

    if (
      newCategory?.products &&
      newCategory?.products[0]?.components?.length === 1 &&
      !newCategory?.products[0]?.components[0].isOutOfStock
    ) {
      newCategory.products[0] = {
        ...newCategory?.products[0],
        numberOfSelection: 1,
      };
    }

    props.preSaveCart({
      category: newCategory,
      restaurant: restaurant,
      mode: props.mode,
      menuId: props.menuId,
    });
    return () => {
      props.resetCategory();
    };
  }, []);

  useEffect(() => {
    if (obligationAlert === true) setShowText((showText) => !showText);
  }, [obligationAlert]);

  const handleCategoryCount = (type: string) => {
    if (type === "INCREASE") {
      setcategoryCount(categoryCount + 1);
      props.updateCategoryCount(type);
    } else if (type === "DECREASE" && categoryCount > 1) {
      setcategoryCount(categoryCount - 1);
      props.updateCategoryCount(type);
    }
  };

  const changeCategory = (
    changedProduct: serviceMenuModels.Product,
    productId: number
  ) => {
    try {
      let category: serviceMenuModels.Category = props.selectedCategory;
      let restaurant: serviceMenuModels.Restaurant = props.menu;

      if (category.products) category.products[productId] = changedProduct;

      category.selected = true;

      props.preSaveCart({
        category: category,
        restaurant: restaurant,
        mode: props.mode,
        menuId: props.menuId,
      });
    } catch (error) {}
  };

  const hasObligation = (components: serviceMenuModels.Component[]) => {
    let nextComponent = components?.filter((el) => el.isNextButton == true);
    return nextComponent?.length == 0;
  };

  const addToCart = async () => {
    if (isAddingToCart) {
      return; // Prevent multiple clicks while processing
    }

    try {
      setIsAddingToCart(true);
      let id = props?.URLParams?.id;

      var presavedCart = _.cloneDeep(props.preSavedCart);
      // const id = props.preSavedCart.restaurant.id;
      let finalCategory = presavedCart.category;

      let obligatoireProducts = finalCategory?.products?.filter((p: any) =>
        hasObligation(p?.components)
      );
      let index = obligatoireProducts?.findIndex(
        (p: any) => !(p.numberOfSelection >= 1)
      );

      let respons = await shopApi.getResturantById({
        id: id,
        diningMode: presavedCart.mode,
        adresse: {
          latitude: 0,
          longitude: 0,
        },
      });

      let restInformation = respons.data.restaurant;

      if (index > -1) {
        setobligationAlert(true);
        let i = obligatoireProducts[index]?.id;
        let elemnt = document.getElementById("product" + i);

        elemnt?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        finalCategory = {
          ...finalCategory,
          quantity: categoryCount,
          amount:
            props.currentPrice == 0 ? finalCategory.price : props.currentPrice,
          selected: true,
          menuId: props.menuId,
        };

        let obj = props.savedCategories;

        let categories = props.savedCategories[id]?.categories
          ? [...props.savedCategories[id].categories, finalCategory]
          : [finalCategory];
        let restau = {
          ...restInformation,
          menus: props?.menu?.menus,
        };
        //this code fix the show of the base and add a single product to cart
        if (
          finalCategory?.products &&
          Array.isArray(finalCategory.products[0]?.components) &&
          finalCategory.products[0].components.length === 1
        ) {
          finalCategory.products[0].components[0].numberOfSelection = 1;
        }

        obj[id] = {
          restaurant: restau,
          categories: categories,
          mode: props.categoryShopMode,
          menuId: props.menuId,
        };

        props.pushCategory(obj);
        setIsAddedToCart(true);
        localStorage.setItem("redirectedToPrevPage", "true");
        navigate(`/restaurant/${restau.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const cartMapper = async (cart: any, id: any) => {
    let respons = await shopApi.getResturantById({
      id: id,
      diningMode: cart.mode,
      adresse: {
        latitude: 0,
        longitude: 0,
      },
    });
    let totalPrice = 0;
    let count = 0;
    await Promise.all(
      cart?.categories?.map((cat: any, i: any) => {
        totalPrice = totalPrice + cat?.amount * cat?.quantity;
        count = count + cat?.quantity;
      })
    );

    return {
      title: respons?.data?.restaurant?.nom,
      count: count,
      restaurant: respons?.data?.restaurant,
      price: totalPrice,
      mode: cart.mode,
      menuId: 0,
      categories: cart.categories,
    };
  };
  const __mapCart = () => {
    let cartItems: any = [];

    Promise.all(
      Object.keys(props.savedCategories).map(async (el, i) => {
        let id = el;

        let cartItem = props.savedCategories[el];

        let mappedCart = await cartMapper(cartItem, id);

        cartItems.push({
          ...mappedCart,
          shownAddress: props.location.shownAddress,
          restaurantId: el,
        });

        // setselectedCategories(mappedCart.categories)
        // if (props?.restaurantIdRedirection != null && props?.restaurantIdRedirection > -1) {
        //     let item = cartItems?.find((cart:any) => cart?.restaurant?.id === restaurantId);
        //    await props.selectOrder(item);
        //     navigate("Orders", null, false)

        // }
      })
    );
    setcartList(cartItems);
  };

  useEffect(() => {
    if (props.savedCategories) {
      __mapCart();
    }
  }, [props.savedCategories]);
  const errorrte = order?.products === null ? order?.name : " ";

  const handleError = () => {
    document.querySelector(".leftSection")?.remove();
  };
  const menuPage = true;
  const preventContextMenu = (e: any) => {
    e.preventDefault();
  };

  return (
    <div id="orderPage">
      <NavBar menuPage={menuPage} />
      <div className="BodyPage">
        <ClippedDrawer
          open={cartOpen}
          setOpen={(bool: boolean) => setCartOpen(bool)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            // marginTop: "10px",
            // marginLeft: "10px",
            // padding: "5px",
            gap: 1,
          }}
        >
          <div className="TopPageHeader">
            <div>
              <img
                onClick={() => {
                  navigate(`/restaurant/${props.URLParams?.id}`);
                  //localStorage.setItem("redirectedToPrevPage", "true");
                }}
                src={LeftArrow}
                alt=""
              />

              <p>{order?.name}</p>
            </div>
          </div>
        </Box>
        <div className="orderView full-size row">
          <div className="leftSection column">
            <div className="imgContainer ">
              <img
                src={order?.image}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt=""
                className="full-size"
                onError={handleError}
                onContextMenu={preventContextMenu}
              />
            </div>
          </div>

          <Stack className="rightSection column " spacing={2}>
            <Stack className="mainMenuInfos" direction={"column"} spacing={2}>
              <Stack direction={"column"}>
                {/* Check if order exists and has a name property */}
                {order && order.name && (
                  <p className="name-cat" style={{ fontSize: "20px" }}>
                    {order.name}
                  </p>
                )}

                {order?.products &&
                order?.products[0]?.components?.length === 1 ? (
                  <p className="name-cat" style={{ fontSize: "14px" }}>
                    {order?.products[0]?.components[0]?.price}€
                  </p>
                ) : (
                  <p></p>
                )}
              </Stack>

              {order?.products &&
              order?.products[0]?.components?.length === 1 ? (
                <p
                  className="name-cat"
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginTop: "-2px",
                  }}
                >
                  {order?.description}
                </p>
              ) : (
                <p></p>
              )}
              <div className="qtyManagerSection">
                <Box>
                  <Stack
                    className="qtyManager"
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    {/* <IconButton> */}
                    <img
                      // disabled={categoryCount == 1 ? true : false}
                      onClick={() => {
                        handleCategoryCount("DECREASE");
                      }}
                      src={MinusOrangeIcon}
                      alt=" MinusOrangeIcon"
                    />

                    {/* <RemoveCircleOutlineIcon />
                  </IconButton> */}
                    <Typography
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        color: "background: #747474;",
                        fontSize: "14px",
                      }}
                    >
                      {categoryCount}
                    </Typography>
                    {/* <IconButton>
                    <AddCircleOutlineRoundedIcon />
                  </IconButton> */}

                    <img
                      onClick={() => {
                        handleCategoryCount("INCREASE");
                      }}
                      src={PlusboxOrangeIcon}
                      alt="PlusboxOrangeIcon"
                    />
                  </Stack>
                </Box>
              </div>
              {/* {!order.ofIsApply ? (
            <div className="acheteXofferSection">
              {order.ofNbAc + " Acheté(s) = " + order.ofNbOf + " Offert(s)"}
            </div>
          ) : null} */}

              {/* {!order.loyaltyPointsProd ? (
            <Stack
              className="fidelitySection row"
              spacing={3}
              direction={"row"}
            >
              <svg
                fill="gold"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M13.211 3.103c-.495-1.004-1.926-1.004-2.421 0L8.432 7.88l-5.273.766c-1.107.161-1.55 1.522-.748 2.303l3.815 3.72-.9 5.25c-.19 1.103.968 1.944 1.958 1.424L12 18.863l4.716 2.48c.99.52 2.148-.32 1.959-1.424l-.9-5.25 3.815-3.72c.8-.78.359-2.142-.749-2.303L15.57 7.88 13.21 3.103ZM1.164 3.782a.75.75 0 0 0 .118 1.054l2.5 2a.75.75 0 1 0 .937-1.172l-2.5-2a.75.75 0 0 0-1.055.118Z" />
                <path d="M22.836 18.218a.75.75 0 0 0-.117-1.054l-2.5-2a.75.75 0 0 0-.938 1.172l2.5 2a.75.75 0 0 0 1.055-.117ZM1.282 17.164a.75.75 0 1 0 .937 1.172l2.5-2a.75.75 0 0 0-.937-1.172l-2.5 2ZM22.836 3.782a.75.75 0 0 1-.117 1.054l-2.5 2a.75.75 0 0 1-.938-1.172l2.5-2a.75.75 0 0 1 1.055.118Z" />
              </svg>

              <Stack className="column " spacing={0.5} direction={"column"}>
                <span style={{ color: "GrayText" }}>
                  {"  Solde FID: "}
                  <b style={{ color: "#D45800" }}> {0}</b>
                </span>

                <div className="row all-center">
                  <Checkbox
                    color="secondary"
                    style={{ height: "30px", width: "30px" }}
                    checked={reductionCheckBox}
                    onChange={(e, checked) =>
                      setReductionCheckBox(!reductionCheckBox)
                    }
                    icon={<RadioButtonUncheckedRoundedIcon />}
                    checkedIcon={<RadioButtonCheckedRoundedIcon />}
                  />
                  {order.loyaltyPointsRetrait +
                    " Points = " +
                    order.loyaltyPointsPrice +
                    "€ de Remise"}{" "}
                </div>
              </Stack>
            </Stack>
          ) : null} */}

              <div className="hide">
                <Stack spacing={1} flex={1} direction={"row"}>
                  {order?.hasAllergene && (
                    <Chip
                      color="primary"
                      size="md"
                      variant="outlined"
                      sx={{ padding: " 0 5px" }}
                      startDecorator={
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 12 12"
                          fill="red"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 1.09091C3.28878 1.09091 1.09091 3.28878 1.09091 6C1.09091 8.71122 3.28878 10.9091 6 10.9091C8.71122 10.9091 10.9091 8.71122 10.9091 6C10.9091 3.28878 8.71122 1.09091 6 1.09091ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
                          />{" "}
                          {/*fill="#026AA2" */}
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.99997 2.90891C5.79914 2.90891 5.63633 3.07172 5.63633 3.27255C5.63633 3.47338 5.79914 3.63619 5.99997 3.63619C6.2008 3.63619 6.3636 3.47338 6.3636 3.27255C6.3636 3.07172 6.2008 2.90891 5.99997 2.90891ZM4.90906 3.27255C4.90906 2.67006 5.39747 2.18164 5.99997 2.18164C6.60246 2.18164 7.09088 2.67006 7.09088 3.27255C7.09088 3.87504 6.60246 4.36346 5.99997 4.36346C5.39747 4.36346 4.90906 3.87504 4.90906 3.27255Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.47013 8.33014C3.94943 8.56419 3.33758 8.33182 3.10352 7.81112L2.10168 5.58236C1.86763 5.06166 2.1 4.44981 2.6207 4.21576V4.21576C3.14139 3.9817 3.75325 4.21407 3.9873 4.73477L4.98914 6.96353C5.2232 7.48423 4.99083 8.09608 4.47013 8.33014V8.33014Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.73418 4.81359C3.67895 4.75077 3.68988 4.65402 3.75775 4.60511L4.05283 4.39243C4.11831 4.34523 4.20981 4.36127 4.25534 4.42792V4.42792C4.2996 4.49271 4.28365 4.58104 4.21951 4.62625L3.92223 4.83585C3.86307 4.87756 3.78197 4.86796 3.73418 4.81359V4.81359Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.27015 5.86682C4.21875 5.79358 4.24491 5.69174 4.32524 5.65233L4.61666 5.50938C4.66576 5.4853 4.72512 5.50416 4.75132 5.55216V5.55216C4.77417 5.59404 4.76499 5.64615 4.72921 5.6777L4.48577 5.8924C4.42064 5.94984 4.32003 5.93791 4.27015 5.86682V5.86682Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.70377 7.04062C4.65378 6.96916 4.67892 6.86998 4.75692 6.83098L5.14149 6.63868C5.1892 6.61482 5.24723 6.63301 5.27278 6.67983V6.67983C5.29594 6.72229 5.28507 6.77526 5.24706 6.80516L4.90912 7.071C4.84465 7.12172 4.7508 7.10783 4.70377 7.04062V7.04062Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.7628 5.8024C1.73938 5.75948 1.75145 5.70584 1.791 5.67709L2.15059 5.41561C2.21201 5.37095 2.29824 5.38617 2.34065 5.44917V5.44917C2.38584 5.51626 2.36176 5.60779 2.28941 5.64397L1.89174 5.84282C1.84489 5.86625 1.7879 5.84838 1.7628 5.8024V5.8024Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.21675 6.77544C2.19374 6.73327 2.20393 6.6807 2.24102 6.65018L2.57156 6.37819C2.63987 6.32198 2.74189 6.33763 2.79021 6.41173V6.41173C2.83865 6.48601 2.81158 6.58592 2.73226 6.62558L2.3494 6.81702C2.3012 6.84113 2.24257 6.82274 2.21675 6.77544V6.77544Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.73934 7.90517C2.71631 7.86296 2.72659 7.81034 2.76381 7.7799L3.11569 7.49217C3.19389 7.42822 3.31164 7.4579 3.35018 7.55127V7.55127C3.38031 7.62425 3.34896 7.7081 3.27835 7.74341L2.8718 7.9467C2.82366 7.97077 2.76511 7.95241 2.73934 7.90517V7.90517Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.65352 4.42384C9.98825 4.48994 10.206 4.81487 10.1399 5.1496L9.98767 5.92066C9.84273 6.65468 9.27065 7.22956 8.53735 7.37808L7.96887 7.49323C7.72443 7.54273 7.53374 7.73436 7.48543 7.97903L7.37307 8.54807C7.22813 9.28209 6.65605 9.85697 5.92275 10.0055L5.15244 10.1615C4.81804 10.2292 4.49205 10.0131 4.42432 9.67867V9.67867C4.35659 9.34427 4.57277 9.01828 4.90717 8.95055L5.67747 8.79453C5.92191 8.74502 6.1126 8.55339 6.16091 8.30872L6.27327 7.73968C6.41821 7.00566 6.99029 6.43078 7.72359 6.28226L8.29208 6.16711C8.53651 6.11761 8.7272 5.92598 8.77551 5.68131L8.92777 4.91024C8.99386 4.57552 9.31879 4.35775 9.65352 4.42384V4.42384Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.45595 8.12156C4.52178 8.07856 4.61049 8.10946 4.63534 8.18406L4.71739 8.43031C4.7322 8.47475 4.71327 8.52344 4.67233 8.5462V8.5462C4.62492 8.57256 4.5651 8.5551 4.53933 8.50736L4.41601 8.27897C4.38638 8.22409 4.40374 8.15567 4.45595 8.12156V8.12156Z"
                          />
                          <path
                            fill="#007FFF"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.39309 3.97195C2.43851 3.94669 2.49523 3.95521 2.53123 3.99268L2.71347 4.18236C2.79878 4.27116 2.7712 4.41757 2.65942 4.46924V4.46924C2.55983 4.51528 2.44263 4.46184 2.41206 4.35647L2.33879 4.10385C2.32393 4.0526 2.34645 3.99788 2.39309 3.97195V3.97195Z"
                          />
                        </svg>
                      }
                    >
                      <Typography
                        level="body-xs"
                        fontWeight={"bold"}
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenAllg(true);
                        }}
                      >
                        Allergènes
                      </Typography>
                    </Chip>
                  )}

                  {/* {order?.hasAllergene && (
                  <List
                    sx={{
                      pt: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {order?.allergenes?.map((allg: any) => {
                      return (
                        <ListItem sx={{ marginTop: "-10px" }} key={allg.name}>
                          <ListItemText primary={allg.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                )} */}

                  {/* {true && (
                <Chip
                  sx={{ padding: 0 }}
                  variant="plain"
                  startDecorator={
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="gold"
                        d="M13.211 3.103c-.495-1.004-1.926-1.004-2.421 0L8.432 7.88l-5.273.766c-1.107.161-1.55 1.522-.748 2.303l3.815 3.72-.9 5.25c-.19 1.103.968 1.944 1.958 1.424L12 18.863l4.716 2.48c.99.52 2.148-.32 1.959-1.424l-.9-5.25 3.815-3.72c.8-.78.359-2.142-.749-2.303L15.57 7.88 13.21 3.103ZM1.164 3.782a.75.75 0 0 0 .118 1.054l2.5 2a.75.75 0 1 0 .937-1.172l-2.5-2a.75.75 0 0 0-1.055.118Z"
                      />
                      <path
                        fill="gold"
                        d="M22.836 18.218a.75.75 0 0 0-.117-1.054l-2.5-2a.75.75 0 0 0-.938 1.172l2.5 2a.75.75 0 0 0 1.055-.117ZM1.282 17.164a.75.75 0 1 0 .937 1.172l2.5-2a.75.75 0 0 0-.937-1.172l-2.5 2ZM22.836 3.782a.75.75 0 0 1-.117 1.054l-2.5 2a.75.75 0 0 1-.938-1.172l2.5-2a.75.75 0 0 1 1.055.118Z"
                      />
                    </svg>
                  }
                >
                  <Typography level="body-xs" textColor={"#D45800"}>
                    {"0 points FID = 0€ de remise"}
                  </Typography>
                </Chip>
              )} */}
                </Stack>
              </div>
            </Stack>

            <Stack className="menuProducts" spacing={2} ref={stackRef}>
              {order?.products?.map((product: any, index: any) => {
                if (
                  product?.maxSelection === 1 &&
                  product.components[0]?.name !== "SUIVANT"
                ) {
                  return (
                    <UniSelection
                      hasObligation={hasObligation}
                      order={order}
                      showText={showText}
                      obj={{ ...product }}
                      index={index}
                      key={index}
                      isOptional={isOptional(product)}
                      updateCurrentPrice={props.updateCurrentPrice}
                      changeCategory={changeCategory}
                      selectCompositions={props.selectCompositions}
                      setSelectedComponents={setSelectedComponents}
                      selectedComponents={selectedComponents}
                    />
                  );
                } else if (product.maxSelection > 1) {
                  return (
                    <MultipleSelection
                      hasObligation={hasObligation}
                      showText={showText}
                      order={order}
                      item={product}
                      index={index}
                      key={index}
                      isOptional={isOptional(product)}
                      currentPrice={props.currentPrice}
                      updateCurrentPrice={props.updateCurrentPrice}
                      updateCompositionsPrice={props.updateCompositionsPrice}
                      changeCategory={changeCategory}
                      setSelectedComponents={setSelectedComponents}
                      selectedComponents={selectedComponents}
                      selectCompositions={props.selectCompositions}
                    />
                  );
                }
              })}
            </Stack>

            {/* <TextField
            multiline
            rows={3}
            placeholder="Laissez un commentaire sur votre ordre"
          ></TextField> */}
            {/* 
          <Button
            className="customFilled"
            variant="contained"
            sx={{ display: { xs: "none", md: "block" } }}
            style={{
              lineHeight: "35px",
              backgroundColor: "#EE8113",
              width: "18rem",
              height: "4rem",
              marginLeft: "5rem",
              borderRadius: "12px",
            }}
            onClick={() => addToCart()}
          >
 
            Ajouter {categoryCount} au panier{" "}
            {order?.products[0]?.components?.length === 1
              ? categoryCount * order.price + "€"
              : ""}
          </Button> */}
            <Button
              className="customFilled btnAddToCart"
              variant="contained"
              sx={{ display: { xs: "none", md: "block" } }}
              // style={{
              //   lineHeight: "35px",
              //   backgroundColor: "#EE8113",
              //   width: "18rem",
              //   height: "4rem",
              //   marginLeft: "5rem",
              //   borderRadius: "12px",
              // }}
              onClick={() => {
                addToCart();
                //openCartInThePrevPage();
              }}
              disabled={isAddingToCart || isAddedToCart}
            >
              Ajouter {categoryCount} au panier{" "}
              {order?.products == null
                ? (order?.price * categoryCount)?.toFixed(2)
                : category?.ofIsApply
                  ? calculatePriceForUnpairedQuantity(
                      categoryCount,
                      props.currentPrice
                    )?.toFixed(2)
                  : (props.currentPrice * categoryCount)?.toFixed(2)}{" "}
              €
            </Button>

            <Button
              className="customFilled hide"
              variant="contained"
              sx={{ display: { xs: "block", md: "none" } }}
              // style={{
              //   lineHeight: "35px",
              //   backgroundColor: "#EE8113",
              //   width: "18rem",
              //   height: "4rem",
              //   marginLeft: "3rem",
              //   borderRadius: "12px",
              // }}
              onClick={() => {
                addToCart();
                //openCartInThePrevPage();
              }}
            >
              Ajouter {categoryCount} au panier{" "}
              {order?.products == null
                ? (order?.price * categoryCount)?.toFixed(2)
                : category?.ofIsApply
                  ? calculatePriceForUnpairedQuantity(
                      categoryCount,
                      props.currentPrice
                    )?.toFixed(2)
                  : (props.currentPrice * categoryCount)?.toFixed(2)}{" "}
              €
            </Button>
            <div className="FooterContainer">
              <Button
                className="customFilled btnAddToCart"
                variant="contained"
                sx={{ display: { xs: "block", md: "none" } }}
                // style={{
                //   lineHeight: "35px",
                //   backgroundColor: "#EE8113",
                //   width: "18rem",
                //   height: "4rem",
                //   marginLeft: "3rem",
                //   borderRadius: "12px",
                // }}
                onClick={() => {
                  addToCart();
                  //openCartInThePrevPage();
                }}
              >
                Ajouter {categoryCount} au panier{" "}
                {order?.products == null
                  ? (order?.price * categoryCount)?.toFixed(2)
                  : category?.ofIsApply
                    ? calculatePriceForUnpairedQuantity(
                        categoryCount,
                        props.currentPrice
                      )?.toFixed(2)
                    : (props.currentPrice * categoryCount)?.toFixed(2)}{" "}
                €
              </Button>
            </div>
          </Stack>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    selectedCategory: state.menu.selectedCategory,
    locations: state.auth.location,
    menuId: state.menu.menuId,
    currentPrice: state.cart.currentPrice,
    compositionsPrice: state.cart.compositionsPrice,
    categoryBasePrice: state.cart.categoryBasePrice,
    menu: state.menu.menu,
    initialMenu: state.menu.initialMenu,
    preSavedCart: state.cart.preSavedCart,
    savedCart: state.cart.savedCart,
    categoryCount: state.cart.categoryCount,
    mode: state.menu.mode,
    priceChangeCount: state.cart.priceChangeCount,
    savedCategories: state.cart.savedCategories,
    activeRestaurantId: state.menu.activeRestaurantId,
    categoryShopMode: state.category.categoryShopMode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCompositions: (
      product: serviceMenuModels.Product,
      productHasCompositionId: number,
      componentId: number
    ) =>
      dispatch(
        selectComposition(product, productHasCompositionId, componentId)
      ),
    updateCart: (finalObj: any) => dispatch(updateCart(finalObj)),
    preSaveCart: (presavedCart: any) => dispatch(preSaveCart(presavedCart)),
    updateCurrentCategory: (
      category: serviceMenuModels.Category,
      menuId: number
    ) => dispatch(updateCurrentCategory(category, menuId)),
    saveCart: (obj: any) => dispatch(saveCart(obj)),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
    resetCategory: () => dispatch(resetCategory()),
    updateCategoryCount: (type: string) => dispatch(updateCategoryCount(type)),
    updateCurrentPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCurrentPrice(newPrice, actionType)),

    updateCompositionsPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCompositionsPrice(newPrice, actionType)),
    getMenu: (body: serviceMenuModels.getMenuBody) =>
      dispatch(getRestaurantMenu(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(OrderView));
