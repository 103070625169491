import React, { useState } from "react";

// import TrashOrange from "../../../images/trashOrange.svg";
// import PlusboxOrange from "../../../images/plusboxOrange.svg";
// import MinusboxOrange from "../../../images/minusboxOrange.svg";
// import foodImg from "../../../images/food.png";

import CharIcon from "../../../images/char.svg";
import CartSubElementsDetails from "./CartSubElementDetails";
import { Commande } from "../../../models/services/module.order";
import SkeletonElement from "../../skeleton/SkeletonElement";
export default function CartElementsDetails({
  orderDetails,
  selectedOrder,
}: {
  orderDetails?: serviceDetailsOrderModels.detailsOrder;
  selectedOrder?: Commande;
}) {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  return (
    <div className="CartElements">
      <div className="CartsElements-listing">
        {!orderDetails && <SkeletonElement type="img-block" />}
        {orderDetails?.detailCommande?.map((command) => (
          <CartSubElementsDetails
            selectedOrder={selectedOrder}
            command={command}
          />
        ))}
      </div>
    </div>
  );
}
